import React, { FC, MouseEvent, useState } from "react";
import c from "classnames";

import { Avatar } from "@omnichat/arm_ui_kit";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { AgentData } from "../../Monitoring";
import { StatsQueue, Subscription } from "./../index";
import hasUserRight from "../../../../Utils/hasUserRight";
import { Tooltip } from "../../../../Components";
import { NETWORKSTATUS } from "../../../../ReactCore/shared/consts";

import * as s from "./styles.module.scss";

interface AgentProps {
  agent?: AgentData;
  onEdit: (id: number) => void;
  onSelect: (id: number) => void;
  appealsLimit?: number;
  current: boolean;
  editable: boolean;
  onTerminate: (id: number) => void;
}

const AgentItem: FC<AgentProps> = ({
  editable,
  current,
  agent: {
    id,
    secName,
    name,
    photo,
    networkStatus,
    appealsCount,
    appealsCountByGroup,
    appealsLimit
  },
  onEdit,
  onSelect,
  onTerminate
}) => {
  const [isHovered, setHovered] = useState(false);
  const hover = () => setHovered(true);
  const dehover = () => setHovered(false);

  const handleSelect = () => {
    !current && onSelect(id);
  };

  const handleEdit = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onEdit(id);
  };

  const handleTerminate = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onTerminate(id);
  };

  const availableSettingsAgentLoad =
    editable && (hasUserRight("agent_limit") || hasUserRight("user_groups"));

  const canTerminate = hasUserRight("forced_logout");

  return (
    <div
      id={`agent-${id}`}
      onClick={handleSelect}
      onMouseOver={hover}
      onMouseLeave={dehover}
      className={c(s["agentItem"], s["clearFix"], {
        [s["current"]]: current
      })}
    >
      {networkStatus !== NETWORKSTATUS.LOGOUT && (
        <StatsQueue
          type="agent"
          id={id}
          appealsCount={appealsCount}
          appealsLimit={appealsLimit}
          isHovered={isHovered}
        />
      )}

      <div className={s["agentBody"]}>
        <div
          className={c(s["agentPhoto"], {
            [s["online"]]: networkStatus === NETWORKSTATUS.ONLINE,
            [s["offline"]]: networkStatus === NETWORKSTATUS.OFFLINE
          })}
        >
          <Avatar
            person={"user"}
            url={photo ? `/fileStorage/files/crop/${photo}` : ""}
          />
        </div>
        <div className={s["agentTextWrap"]}>
          <div>
            <div
              id={`agent_name_${id}`}
              className={c(s["agentName"], { [s["hover"]]: isHovered })}
            >
              <Tooltip
                content={`${secName} ${name}`}
                enterDelay={1000}
                followCursor
              >
                <span>{`${secName} ${name}`}</span>
              </Tooltip>
            </div>

            <div className={s["agentRight"]}>
              {availableSettingsAgentLoad && (
                <div
                  id={`manageUser_${id}`}
                  onClick={handleEdit}
                  className={c(s["agentIcon"], s["agentIconTop"])}
                >
                  <SettingsIcon
                    id={`agent_icon_${id}`}
                    width="20px"
                    height="20px"
                  />
                </div>
              )}
              {canTerminate && networkStatus !== NETWORKSTATUS.LOGOUT && core?.user?.id !== id && (
                <div
                  className={c(s["agentIcon"], s["agentIconBottom"])}
                  onClick={handleTerminate}
                >
                  <ExitToAppIcon width="20px" height="20px" />
                </div>
              )}
            </div>
          </div>
          <div className={c(s["agentAppeals"], s["clearFix"])}>
            <Subscription
              id={`agent-${id}`}
              appealsCount={appealsCountByGroup}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentItem;

import { takeEvery } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { sendTransferData } from "../Actions/TransferAppealModal.actionCreators";
import Informer from "../../../arm/Wrappers/Informer";

/**
 * Сага для трансфера обращения
 *
 * @param {any} action Экшн.
 */
function* transferAppeal(action) {
  const { appealId, groupId, userId, comment, cb } = action.payload;
  try {
    const response = yield httpRequest("POST", `bff/transfer`, {
      body: {
        appealId,
        groupId,
        userId,
        comment
      }
    });
    if (response?.success) {
      if (response?.data?.data?.success) {
        const info = new Informer("Обращение успешно передано", 1500);
        info.show();
        cb && cb();
      } else {
        let errorText = "Исправьте ошибки перед отправкой формы";

        if (response?.data?.errors?.["101"]) {
          errorText = "Агент offline";
        } else if (response?.data?.errors?.["102"]) {
          errorText = "Обращение уже распределено на этого оператора";
        } else if (response?.data?.errors?.["105"]) {
          errorText = "Операция не доступна";
        } else if (response?.data?.errors?.["12"]) {
          errorText = "Обращение уже закрыто, перезагрузите страницу";
        }

        const info = new Informer(errorText, 1500);
        info.show();
      }
    }
  } catch (error) {
    let errorText = "Возникла неизвестная ошибка";
    const info = new Informer(errorText, 1500);
    info.show();
  }
}

export function* transferAppealSaga() {
  yield takeEvery([sendTransferData], transferAppeal);
}

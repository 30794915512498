import { fork } from "redux-saga/effects";

import getAgens from "./getAgents.saga";
import getSkillGroups from "./getSkillGroups.saga";
import RealtimeWs from "./realTimeWs.saga";
import sendTerminateSaga from "./sendTerminate.saga"

export default function* () {
  yield fork(getAgens);
  yield fork(getSkillGroups);
  yield fork(RealtimeWs);
  yield fork(sendTerminateSaga);
}

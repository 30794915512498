import { takeEvery } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { sendReopenData } from "../Actions/ReopenAppealModal.actionCreators";
import Informer from "../../../arm/Wrappers/Informer";

/**
 * Сага для трансфера обращения
 *
 * @param {any} action Экшн.
 */
function* sendReopenSaga(action) {
  const { appealId, groupId, userId, comment, cb } = action.payload;
  try {
    const response = yield httpRequest("POST", `bff/reopen`, {
      body: {
        appealId,
        groupId,
        userId,
        comment
      }
    });
    if (response?.success) {
      const info = new Informer("Обращение успешно открыто", 1500);
      info.show();
      cb && cb();
    } else {
      const info = new Informer("Исправьте ошибки перед отправкой формы", 1500);
      info.show();
    }
  } catch (error) {
    let errorText = "Возникла неизвестная ошика";
    if (/"101":"user offline"/.test(error)) {
      errorText = "Агент offline";
    } else if (/"102": "same users"/.test(error)) {
      errorText = "Обращение уже распределено на этого оператора";
    } else if (/"105"/.test(error)) {
      errorText = "Операция не доступна";
    } else if (/"12": "Appeal was closed"/.test(error)) {
      errorText = "Обращение уже закрыто, перезагрузите страницу";
    }
    const info = new Informer(errorText, 1500);
    info.show();
  }
}

export function* reopenAppealSaga() {
  yield takeEvery([sendReopenData], sendReopenSaga);
}

import { fork } from "redux-saga/effects";

import getChartsAht from "./getChartsAht.saga";
import getChartsAppeals from "./getChartsAppeals.saga";
import getChartsCsi from "./getChartsCsi.saga";
import getChartsLcr from "./getChartsLcr.saga";
import getChartsOrt from "./getChartsOrt.saga";
import getChartsSl from "./getChartsSl.saga";

export default function*() {
  yield fork(getChartsAht);
  yield fork(getChartsAppeals);
  yield fork(getChartsCsi);
  yield fork(getChartsLcr);
  yield fork(getChartsOrt);
  yield fork(getChartsSl);
}

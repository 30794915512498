import { takeEvery } from "redux-saga/effects";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { sendReopenData } from "../Actions/ReopenAppealModal.actionCreators";
import Informer from "../../../arm/Wrappers/Informer";

/**
 * Сага для трансфера обращения
 *
 * @param {any} action Экшн.
 */
function* sendReopenSaga(action) {
  const { appealId, groupId, userId, comment, reopenFromChat, cb } = action.payload;
  try {
    const path = reopenFromChat ? `bff/reopen` : `bff/dashboard/appeal/reopen`;
    
    const response = yield httpRequest("POST", path, {
      body: {
        appealId,
        groupId,
        userId,
        comment
      }
    });
    if (response?.success) {
      if (response?.data?.data?.success) {
        const info = new Informer("Обращение успешно открыто", 1500);
        info.show();
        cb && cb();
      } else {
        let errorText = "Возникла неизвестная ошибка";

        if (response?.data?.errors?.["101"]) {
          errorText = "Агент offline";
        } else if (response?.data?.errors?.["102"]) {
          errorText = "Обращение уже распределено на этого оператора";
        } else if (response?.data?.errors?.["105"]) {
          errorText = "Операция не доступна";
        } else if (response?.data?.errors?.["12"]) {
          errorText = "Обращение уже закрыто, перезагрузите страницу";
        }

        const info = new Informer(errorText, 1500);
        info.show();
      }
    } else {
      const info = new Informer("Исправьте ошибки перед отправкой формы", 1500);
      info.show();
    }
  } catch (error) {
    let errorText = "Возникла неизвестная ошибка";
    if (/"101":"user offline"/.test(error)) {
      errorText = "Агент offline";
    } else if (/"102": "same users"/.test(error)) {
      errorText = "Обращение уже распределено на этого оператора";
    } else if (/"105"/.test(error)) {
      errorText = "Операция не доступна";
    } else if (/"12": "Appeal was closed"/.test(error)) {
      errorText = "Обращение уже закрыто, перезагрузите страницу";
    }
    const info = new Informer(errorText, 1500);
    info.show();
  }
}

export function* reopenAppealSaga() {
  yield takeEvery([sendReopenData], sendReopenSaga);
}

import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createAction,
  createSlice,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";

import initialState from "../../../ReactCore/store/initialState";
import {
  SkillGroupData,
  AgentData,
  UserRealTime,
  GroupRealTime,
  OverallRealTime
} from "../Monitoring.d";
import { filteredDuplicateArray } from "../../../ReactFeatures/Common/Utils/CommonUtils";

import { IWsOptions } from "../../../Websocket/Websocket";

const NAMESPACE = "monitoring/";

export const GET_SKILLGROUPS = `${NAMESPACE}GET_SKILLGROUPS`;
export const GET_AGENTS = `${NAMESPACE}GET_AGENTS`;

export const CONNECT_WS = `${NAMESPACE}CONNECT_MONITORING_WS`;
export const DISCONNECT_WS = `${NAMESPACE}DISCONNECT_MONITORING_WS`;

export const SEND_TERMINATE = `${NAMESPACE}SEND_TERMINATE`

const getAgentListAction = createAction<{
  page?: number;
  size?: number;
  query?: string;
  skill_id?: string;
}>(GET_AGENTS);

const getSkillGroupListAction = createAction(GET_SKILLGROUPS);

const connectRealtimeWs = createAction<IWsOptions>(CONNECT_WS);
const disconnectRealtimeWs = createAction(DISCONNECT_WS);

const sendTerminate = createAction<{userId: number, cb: Function}>(SEND_TERMINATE);

const MonitoringSlice = createSlice({
  name: "monitoring",
  initialState: initialState.Monitoring,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setAgentList(state, action: PayloadAction<AgentData[]>) {
      state.agentList = action.payload || [];
    },
    addAgentIntoList(state, { payload }: PayloadAction<AgentData[]>) {
      const filteredAgentList = filteredDuplicateArray(
        state.agentList,
        payload
      );
      state.agentList = [...filteredAgentList, ...payload];
    },
    updateAgentMonitoringData(
      state,
      action: PayloadAction<{ [key: string]: UserRealTime }>
    ) {
      state.monitoringAgents = action.payload;
    },
    changeAgentPage(state, action: PayloadAction<number>) {
      state.currentAgentsPage = action.payload;
    },
    resetAgentPage(state) {
      state.currentAgentsPage = initialState.Monitoring.currentAgentsPage;
    },
    setSkillGroupsList(state, action: PayloadAction<SkillGroupData[]>) {
      state.skillGroupList = [
        ...initialState.Monitoring.skillGroupList,
        ...action.payload
      ];
    },
    updateOverallMonitoringData(state, action: PayloadAction<OverallRealTime>) {
      state.monitoringOverall = action.payload;
    },
    updateSkillGroupMonitoringData(
      state,
      { payload }: PayloadAction<{ [key: string]: GroupRealTime }>
    ) {
      const monitoringGroups = {};
      for (let g in payload) {
        const group = payload[g];
        if (
          group.appeals > 0 ||
          group.queued.count > 0 ||
          group.agents_online > 0
        ) {
          monitoringGroups[g] = group;
        }
      }

      state.monitoringGroups = monitoringGroups;
    },
    setQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    setAgentEdited(state, action: PayloadAction<AgentData["id"]>) {
      state.agentData = action.payload;
    },
    cleanAgentEdited(state) {
      state.agentData = null;
    },
    setSkillGroupEdited(state, action: PayloadAction<SkillGroupData["id"]>) {
      state.skillGroupData = action.payload;
    },
    cleanSkillGroupEdited(state) {
      state.skillGroupData = null;
    },
    setCurrentSkillGroup(state, action: PayloadAction<SkillGroupData["id"]>) {
      state.currentSkillGroupId = action.payload;
    },
    setCurrentAgent(state, action: PayloadAction<AgentData["id"]>) {
      state.currentAgentId = action.payload;
    },
    resetCurrentSkillGroup(state) {
      state.currentSkillGroupId = initialState.Monitoring.currentSkillGroupId;
    },
    resetCurrentAgent(state) {
      state.currentAgentId = initialState.Monitoring.currentAgentId;
    },
    startDashboard(state) {
      state.agentList = [...initialState.Monitoring.agentList];
      state.skillGroupList = [...initialState.Monitoring.skillGroupList];
      state.currentSkillGroupId = initialState.Monitoring.currentSkillGroupId;
      state.currentAgentId = initialState.Monitoring.currentAgentId;
      state.agentData = null;
      state.skillGroupData = null;
      state.query = "";
    }
  }
});

export const actionAgents = {
  ...MonitoringSlice.actions,
  getAgentListAction,
  getSkillGroupListAction,
  connectRealtimeWs,
  disconnectRealtimeWs,
  sendTerminate
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionAgents, dispatch), [dispatch]);
};

export default MonitoringSlice.reducer;

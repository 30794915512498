import React, { FC, memo } from "react";
import { Typography, Grid } from "@mui/material";

import { EditRoleButton, DeleteRoleButton } from "../index";
import { rightsDTO, Role } from "../../Roles";
import { ADMIN_ROLE_ID } from "../../const";

import RightView from "./RightView";

interface Props {
  role: Role;
  rights: rightsDTO;
  onEditRole: () => void;
  onDeleteRole: () => void;
}

const RoleView: FC<Props> = ({ role, rights, onEditRole, onDeleteRole }) => (
  <>
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid
        item
        display="flex"
        alignSelf="center"
        alignItems="center"
        xs={9}
        gap={2}
      >
        <Typography variant="h3">Название роли:</Typography>
        <Typography variant="body1">{role.name}</Typography>
      </Grid>
      <Grid
        item
        display="flex"
        gap={1}
        justifyContent="right"
        alignItems="center"
        xs={3}
      >
        <DeleteRoleButton
          disabled={rights.roleId === ADMIN_ROLE_ID}
          onClick={onDeleteRole}
        />
        <EditRoleButton
          disabled={rights.roleId === ADMIN_ROLE_ID}
          onClick={onEditRole}
        />
      </Grid>
      <Grid item alignItems="center" xs={12} marginTop={2}>
        <Typography variant="h3">Права доступа:</Typography>
      </Grid>
      <Grid item alignItems="center" xs={12} marginTop={2} marginBottom={1}>
        <Typography variant="h4">Чат</Typography>
      </Grid>

      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.agent}
          caption="Обращения – Обмен сообщениями, перевод и закрытие обращений"
        />
      </Grid>

      <Grid item alignItems="center" xs={12} marginTop={3}>
        <Typography variant="h4">Дашборд</Typography>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
        marginTop={1}
        marginLeft={4}
      >
        <Grid item alignItems="center" xs={12}>
          Статистика
        </Grid>
        <Grid item alignItems="center" xs={12}>
          <RightView
            isActive={rights.personDashboard}
            caption="Просмотр персональной статистики"
          />
        </Grid>
        <Grid item alignItems="center" xs={12}>
          <RightView
            isActive={rights.systemDashboard}
            caption="Просмотр общей статистики"
          />
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          marginTop={1}
          marginLeft={8}
        >
          <Grid item alignItems="center" xs={12}>
            <RightView
              isActive={rights.monitoring}
              caption="Мониторинг нагрузки"
            />
          </Grid>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            marginTop={1}
            marginLeft={4}
          >
            <Grid item alignItems="center" xs={12}>
              <RightView
                isActive={rights.skillLimit}
                caption="Управление нагрузкой на группу"
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <RightView
                isActive={rights.agentLimit}
                caption="Управление нагрузкой на сотрудника"
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <RightView
                isActive={rights.userGroups}
                caption="Редактирование групп сотрудника"
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <RightView
                isActive={rights.forcedLogout}
                caption="Принудительное завершение сессии сотрудника"
              />
            </Grid>
          </Grid>
          <Grid item alignItems="center" xs={12} marginTop={1}>
            Обращения
          </Grid>
          <Grid item alignItems="center" xs={12}>
            <RightView
              isActive={rights.appealClose}
              caption="Закрытие обращения"
            />
          </Grid>
          <Grid item alignItems="center" xs={12}>
            <RightView
              isActive={rights.appealTransfer}
              caption="Перевод обращения"
            />
          </Grid>
          <Grid item alignItems="center" xs={12}>
            <RightView
              isActive={rights.appealReopen}
              caption="Открытие ранее закрытого обращения"
            />
          </Grid>
          <Grid item alignItems="center" xs={12} marginTop={1}>
            Отчетность
          </Grid>
          <Grid item alignItems="center" xs={12}>
            <RightView
              isActive={rights.excel}
              caption="Выгрузка отчетов в Excel"
            />
          </Grid>
          <Grid item alignItems="center" xs={12}>
            <RightView
              isActive={rights.reportAppeals}
              caption="Выгрузка обращений"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item alignItems="center" xs={12}>
        <Typography variant="h4" marginTop={3} marginBottom={1}>
          Настройки
        </Typography>
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.templates}
          caption="Шаблоны – Создание, редактирование и удаление"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.classifier}
          caption="Конструктор форм – Создание, редактирование и удаление"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.createUsers}
          caption="Профили сотрудников – Создание, редактирование и удаление"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.skills}
          caption="Группы – Создание, редактирование и удаление"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.channels}
          caption="Каналы – Создание, редактирование и удаление"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.autoreplies}
          caption="Автоответы – Создание, редактирование и удаление автоответов"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.bots}
          caption="Чат-боты – Создание, редактирование и удаление"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.roles}
          caption="Роли – Создание, редактирование и удаление ролей"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.vip}
          caption="Vip – Просмотр, редактирование распределения обращений vip-пользователей, vip-скиллгрупп"
        />
      </Grid>
      <Grid item alignItems="center" xs={12}>
        <RightView
          isActive={rights.systemSettings}
          caption="Системные настройки – Просмотр, редактирование"
        />
      </Grid>
    </Grid>
  </>
);

export default memo(RoleView);

/**
 * Создаст timestamp, который приведен к московскому времени.
 *
 * @param {Date} date Дата, которую необходимо преобразовать в timestamp.
 */
export function convertToMoscowTimestamp(date: Date): number {
  if (!date || !(date instanceof Date)) return;

  let result = date.getTime();
  const time = date.toTimeString().slice(0, 8);
  const diffInMinutesWithUTC = date.getTimezoneOffset();

  if (diffInMinutesWithUTC === -180) return date.getTime(); // если текущее смещение от UTC +03:00, возвращает сразу timestamp.

  let month: string | number = date.getMonth() + 1;
  month = month > 9 ? month : `0${month}`;
  let day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
  // Собираем строку формата ISOString для получения времени в мс искомого часового пояса.
  // yyyy-mm-ddThh:mm:ss.msms+03:00
  result = Date.parse(
    `${date.getFullYear()}-${month}-${day}T${time}.000+03:00`
  );
  return result;
}

/**
 * Создаст локальную дату из UTCTimestamp.
 *
 * @param {number} UTCTimestamp Unix timestamp in UTC.
 * @param {string} [time] Время, которое необходимо проставить по умолчанию.
 */
export function createDateFromUTCTimestamp(
  UTCTimestamp: number,
  time?: string
): Date {
  let result = new Date();

  if (typeof UTCTimestamp === "number" && isFinite(UTCTimestamp)) {
    let timestamp = UTCTimestamp;
    const diff = result.getTimezoneOffset() / 60; //смотрим разницу в часах между UTC и локальным верменем

    if (3 + diff) {
      // если это часовой пояс отличный от +03:00
      const utcOffset = 3 + diff; // считаем разницу в часах от пояса +03:00
      // если местный часовой пояс больше +3 будет отрицательный результат и мы получим отрицательное смещение
      // если местный часовой пояс меньше +3 будет положительный результат и мы получим положительное смещение
      timestamp += utcOffset * 60 * 60; // прибавим смещение к timestamp
      // в результате получим timestamp который нужен для того, чтобы при создании местного времени
      // мы полутали время будто бы московское
    }

    result = new Date(timestamp * 1000);
  } else if (time) {
    const [hours, minutes, seconds] = time.split(":");

    isFinite(+hours) && result.setHours(+hours);
    isFinite(+minutes) && result.setMinutes(+minutes);
    isFinite(+seconds) && result.setSeconds(+seconds);
  }

  return result;
}

/**
 * Переведет дату формата Date в unix timespamp.
 *
 * @param {Date | number} date Дата.
 * @param {string} [time] Время, которое необходимо проставить по умолчанию.
 */
export const toUnixTimeStamp = (date: Date | number, time?: string): number => {
  let result: Date | number = new Date(); // по умолчанию.

  if (time) {
    const [hours, minutes, seconds] = time.split(":");

    isFinite(+hours) && result.setHours(+hours);
    isFinite(+minutes) && result.setMinutes(+minutes);
    isFinite(+seconds) && result.setSeconds(+seconds);

    result = result.getTime();
  }

  if (date instanceof Date) {
    result = date.getTime();
  } else if (date && typeof date === "number") {
    result = date;
  }

  return Math.floor((result as number) / 1000);
};

/**
 * Перевод дат периода из String в Date.
 *
 * @param {String} stringData Дата в формате строки.
 * @returns {Date}
 */
export const stringToDate = function(stringData) {
  if (!stringData) return false;
  stringData = stringData.split(".");
  return new Date(stringData[2], stringData[1] - 1, stringData[0]);
};

/**
 * Перевод дат периода из Date в String.
 *
 * @param {Date} d Дата в формате Date.
 * @returns {String}
 */
export const dateToFormatString = (d) => {
  let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let month = d.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let year = d.getFullYear();
  return day + "." + month + "." + year;
};

import { takeEvery, call } from "redux-saga/effects";
import { createWsApiSaga } from "../../../Stomp/Stomp.saga";
import {
  sendUserEvent,
  incomingMessageRoutine,
  incomingPingRoutine,
  sendPongEvent
} from "../../../Stomp/Stopm.actions";
import { initSystemStatisticStomp } from "../Store/SystemStatistics.reducer";

const publishDefinitions = [
  {
    path: "/app/event",
    routine: sendUserEvent
  },
  {
    path: "/app/pong",
    routine: sendPongEvent
  }
];
const subscriptions = [
  {
    path: "/user/queue/ping",
    routine: incomingPingRoutine,
    convertPayload: (body) => JSON.parse(body)
  }
];

function* initSystemStatisticStompSaga() {
  const maxConnectionAttempts = STATISTICS_STOMP_RECONNECT_ATTEMPTS || 15;
  let currentTry = 0;

  const stompSaga = yield createWsApiSaga(
    {
      sockJsEndpoint: `${location.protocol}//${location.host}/statistics/stomp`,
      verboseLogging: SENTRY_DEBUG_GROUP_4 === "1",
      headers: {
        hash: window.core.user.hash
      },
      beforeConnect: (client) => {
        return () => {
          currentTry++;
          if (currentTry > maxConnectionAttempts) {
            client.deactivate();
          }
        };
      },
      onConnect: () => {
        currentTry = 0;
      }
    },
    {
      subscriptions,
      publishDefinitions
    }
  );

  yield call(stompSaga);
}

export default function*() {
  yield takeEvery(initSystemStatisticStomp, initSystemStatisticStompSaga);
}

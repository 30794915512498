import { takeLatest, put, cancelled } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/Charts.reducer";

export let controller;

function* getChartOrtSaga({ payload: { filters }, type }) {
  const name = "ort";
  controller = new AbortController();

  yield put(actions.startLoading(`${type}/${name}`));

  try {
    const res = yield httpRequest("GET", `bff/dashboard/charts/stats_${name}`, {
      query: filters,
      signal: controller.signal
    });

    if (res.success) {
      yield put(
        actions.setChart({
          [name]: res.data?.data
        })
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
    yield put(actions.finishLoading(`${type}/${name}`));
  }
}

export default function*() {
  yield takeLatest([actions.getChartOrt], getChartOrtSaga);
}

import { Client, IFrame, IMessage, StompHeaders } from "@stomp/stompjs";
import SockJS, { CloseEvent } from "sockjs-client";
import * as Sentry from "@sentry/react";
import { Task } from "redux-saga";
import {
  call,
  fork,
  race,
  put,
  all,
  take,
  cancel,
  cancelled,
  takeEvery
} from "redux-saga/effects";
import { ActionFunction1, Action } from "redux-actions";
import {
  stompConnected,
  stompDisconnected,
  disconnectStomp,
  DISCONNECT_ACTION
} from "./Stopm.actions";

type ActionCreator<Payload> = ActionFunction1<Payload, Action<Payload>>;
export interface StompConnectionConfig {
  sockJsEndpoint: string;
  verboseLogging: boolean;
  headers?: StompHeaders;
  beforeConnect?: (client: Client) => () => void;
}
export interface PromiseFactory<T> {
  getPromise: () => Promise<T>;
}

export type CreatePromiseFactory = <T>(
  callbackConfigurer: CallbackConfigurer<T>
) => PromiseFactory<T>;

export type CallbackConfigurer<T> = (
  successCallback: (result: T) => void
) => void;

export type CreateStompClientCallbackConfigurer = (
  client: Client
) => CallbackConfigurer<IFrame>;
export type CreateStompClientDisconnectCallbackConfigurer = (
  client: Client
) => CallbackConfigurer<CloseEvent>;

export type CreateSubscriptionCallbackConfigurer = (
  subscription: StompSubscription<any>,
  client: Client
) => CallbackConfigurer<IMessage>;

export interface StompSubscription<P> {
  path: string;
  routine: any;
  convertPayload: (payload: any) => P;
}

export interface StompPublishApi {
  path: string;
  routine: any;
}
export interface StompApiDefinition {
  subscriptions: ReadonlyArray<StompSubscription<any>>;
  publishDefinitions: ReadonlyArray<StompPublishApi>;
}

const verboseLogger = (str: string) => {
  const isConnecting = new RegExp(`CONNECT`);
  const isConnected = new RegExp(`CONNECTED`);

  if (isConnecting.test(str)) {
    Sentry.withScope((scope) => {
      scope.setExtra("Data", { str });
      Sentry.captureMessage(`Stomp CONNECTING user=${core.user.id}`, "debug");
    });
  }

  if (isConnected.test(str)) {
    Sentry.withScope((scope) => {
      scope.setExtra("Data", { str });
      Sentry.captureMessage(`Stomp CONNECTED user=${core.user.id}`, "debug");
    });
    console.log(`::: ${str}`);
  }
};
const nilLogger = (_str: string) => null;

const createStompClient = (config: StompConnectionConfig) => {
  const sock = () => new SockJS(config.sockJsEndpoint);
  const client = new Client({
    webSocketFactory: sock,
    connectHeaders: config.headers || {},
    debug: config.verboseLogging ? verboseLogger : nilLogger,
    reconnectDelay: 5000,
    heartbeatIncoming: 5000,
    heartbeatOutgoing: 0
  });

  client.beforeConnect = config.beforeConnect?.(client);

  return client;
};

const createStompClientConnectionCallbackConfigurer: CreateStompClientCallbackConfigurer = (
  client
) => (res) => {
  client.onConnect = res;
};

const createStompClientDisconnectionCallbackConfigurer: CreateStompClientDisconnectCallbackConfigurer = (
  client
) => (res) => {
  client.onWebSocketClose = res;
};

const createSubscriptionCallbackConfigurer: CreateSubscriptionCallbackConfigurer = (
  subscription: StompSubscription<any>,
  client: Client
) => (res) => client.subscribe(subscription.path, (payload) => res(payload));

const createSubscriptionSaga = (
  subscription: StompSubscription<any>,
  client: Client
) => {
  function* handleSubscribedMessage(action: IMessage) {
    const converted = yield call(subscription.convertPayload, action.body);

    yield put(
      subscription.routine.success({
        body: converted,
        headers: action.headers
      })
    );
  }

  const subscriptionCallbackConfigurer: CallbackConfigurer<IMessage> = createSubscriptionCallbackConfigurer(
    subscription,
    client
  );

  const subscriptionSaga = createCallbackReactingSaga(
    `subscription: ${subscription.path}`,
    subscriptionCallbackConfigurer,
    subscription.routine.trigger,
    handleSubscribedMessage,
    true,
    "unsubscribe"
  );

  return function*() {
    try {
      yield fork(subscriptionSaga);
    } finally {
      if (yield cancelled()) {
        console.log(`subscription saga for ${subscription.path} was cancelled`);
      }
    }
  };
};

const createPublisher = (client: Client) => (
  destination: string,
  payload: any
) => {
  client.publish({
    destination,
    body: JSON.stringify(payload)
  });
};

const createPublishSaga = (api: StompPublishApi, client: Client) => {
  const doPublish = createPublisher(client);
  return function*() {
    try {
      console.log(`publish (${api.path}) saga started`);
      while (true) {
        const { payload } = yield take(api.routine.TRIGGER);
        yield call(doPublish, api.path, payload);
      }
    } finally {
      if (yield cancelled()) {
        console.log(`publish (${api.path}) saga cancelled`);
      }
    }
  };
};

const createOnConnectedSaga = (client: Client, api: StompApiDefinition) => {
  const stompClientDisconnectionCallbackConfigurer = createStompClientDisconnectionCallbackConfigurer(
    client
  );

  const initDisconnect = function*() {
    console.log("deactivate");
    client.deactivate();
  };

  const disconnectedSaga = createCallbackSaga(
    "stompOnDisconnect",
    stompClientDisconnectionCallbackConfigurer,
    stompDisconnected
  );

  return function*() {
    yield takeEvery(disconnectStomp, initDisconnect);
    yield fork(disconnectedSaga);
    yield all([
      ...api.subscriptions.map((s) => createSubscriptionSaga(s, client)()),
      ...api.publishDefinitions.map((p) => createPublishSaga(p, client)())
    ]);
  };
};

export const createWsApiSaga = (
  config: StompConnectionConfig,
  api: StompApiDefinition
) => {
  const client = createStompClient(config);

  const stompClientConnectionCallbackConfigurer = createStompClientConnectionCallbackConfigurer(
    client
  );

  const stompConnectedSaga = createCallbackReactingSaga(
    "stompOnConnect",
    stompClientConnectionCallbackConfigurer,
    stompConnected,
    createOnConnectedSaga(client, api),
    true,
    DISCONNECT_ACTION
  );

  client.activate();

  return function* wsApiSaga() {
    try {
      yield fork(stompConnectedSaga);
    } finally {
      const wasCancelled = yield cancelled();
      if (wasCancelled) {
        console.log("wsApiSaga ended gracefully");
      }
    }
  };
};

export const createCallbackSaga = <P>(
  name: string,
  callbackConfigurer: CallbackConfigurer<any>,
  action: ActionCreator<P> | string,
  repeating: boolean = false,
  cancelAction?: string
) => {
  const promiseFactory = createPromiseFactory(callbackConfigurer);
  const promiseHandlingSaga = createPromiseHandlingSaga(
    name,
    action,
    promiseFactory,
    repeating
  );

  return function*() {
    const task: Task = yield fork(promiseHandlingSaga);
    if (cancelAction) {
      yield take(cancelAction);
      yield cancel(task);
    }
  };
};

const createPromiseHandlingSaga = <T>(
  name: string,
  action: ActionCreator<T> | string,
  promiseFactory: PromiseFactory<T>,
  repeating: boolean = true
) => {
  return function*() {
    let payload: T | null = yield call(promiseFactory.getPromise);
    try {
      while (payload) {
        if (typeof action === "string") {
          yield put({
            type: action,
            payload
          });
        } else {
          yield put(action(payload));
        }
        if (repeating) {
          payload = yield call(promiseFactory.getPromise);
        } else {
          payload = null;
        }
      }
    } finally {
      if (yield cancelled()) {
        yield call(console.log, `promisified callback (${name}) cancelled`);
      } else {
        yield call(
          console.log,
          `promisified callback (${name}) ended gracefully`
        );
      }
    }
  };
};

export const createCallbackReactingSaga = <P>(
  name: string,
  callbackConfigurer: CallbackConfigurer<any>,
  action: ActionCreator<P> | string,
  reactingSaga: any,
  repeating: boolean = false,
  cancelAction?: string
) => {
  const promiseFactory = createPromiseFactory(callbackConfigurer);
  const promiseHandlingSaga = createPromiseHandlingSaga(
    name,
    action,
    promiseFactory,
    repeating
  );

  return function*() {
    try {
      const promiseTask: Task = yield fork(promiseHandlingSaga);

      let forkedTask: Task | undefined;
      let cancelled = false;

      while (!cancelled) {
        let promiseResult;
        if (cancelAction) {
          const { promise, cancellation } = yield race({
            promise: take(action),
            cancellation: take(cancelAction)
          });
          promiseResult = promise;
        } else {
          promiseResult = yield take(action);
        }
        if (promiseResult) {
          if (forkedTask && forkedTask.isRunning()) {
            yield cancel(forkedTask);
          }

          forkedTask = yield fork(reactingSaga, promiseResult.payload);
        } else {
          // Убрал эту логику так как при автоматическом пересоздании сокета удаляются подписчики и публишеры
          // и не пересоздаются повторно.
          // if (forkedTask && forkedTask.isRunning()) {
          //   yield cancel(forkedTask);
          // }
          // // if (!repeating) {
          // yield cancel(promiseTask);
          // cancelled = true;
          // // }
        }
      }
    } finally {
      if (yield cancelled()) {
        console.log(`callback reaction saga (${name}) was cancelled`);
      }
    }
  };
};

const createPromiseFactory: CreatePromiseFactory = <T>(
  configureCallbacks: CallbackConfigurer<T>
): PromiseFactory<T> => {
  let deferred: any;

  const callbackFunction = (frame: T) => {
    if (deferred) {
      deferred.resolve(frame);
      deferred = null;
    }
  };

  configureCallbacks(callbackFunction);

  return {
    getPromise: function() {
      if (!deferred) {
        deferred = {};
        deferred.promise = new Promise<T>((resolve) => {
          deferred.resolve = resolve;
        });
      }
      return deferred.promise;
    }
  };
};

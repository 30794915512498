import { isDate, setHours, setMinutes, setSeconds } from "date-fns";

/**
 * Метод для получения количества дней в месяце
 * @param {Date} date дата
 * @returns {Number}
 */
function getDaysInMonth(date: Date) {
  // if you'll set non-existent day (in our case = 33) to Date obj, you'll get the corresponding day of the next month (33.01 -> returns 2, so daysInMonth would be 33 - 2 = 31)
  return 33 - new Date(date.getFullYear(), date.getMonth(), 33).getDate();
}

/**
 * Метод добавляющий/отнимающий месяцы к/от переданной даты
 * @param {Date} date дата для редактирвоания
 * @param {Number} value количество месяцев. Если значение отрицательное, метод будет отнимать месяцы!
 * @returns {Date} преобразованная дата
 */
export function addMonths(date: Date, value: number) {
  var d = new Date(date);
  var n = date.getDate();
  var daysInCurrentMonth = getDaysInMonth(date);

  d.setHours(0, 0, 0);
  d.setDate(1);
  d.setMonth(d.getMonth() + value);

  var daysInUpdatedMonth = getDaysInMonth(d);

  // assure that 28.02.2018 plus 1 month will be 31.03.2018
  var changeDay =
    daysInUpdatedMonth !== daysInCurrentMonth ? daysInCurrentMonth - n : null;

  changeDay = changeDay > n ? null : changeDay;

  var dateToSet =
    changeDay !== null
      ? daysInUpdatedMonth - changeDay
      : Math.min(n, daysInUpdatedMonth);

  // +1 требуется чтобы не привышать лимит в 31 или 92 дня
  d.setDate(dateToSet + 1);
  return d;
}

export const getToday = () => {
  return new Date();
};

export const getTomorrow = () => {
  const today = getToday();
  return today.setDate(getToday().getDate() + 1);
};

export const getYesterday = () => {
  let date = getToday();
  date.setDate(date.getDate() - 1);
  return date;
};

export const getWeekPeriod = () => {
  let date = getToday();
  date.setDate(date.getDate() - 7);
  return {
    start: date,
    end: getToday()
  };
};

export const getMonthPeriod = () => {
  let date = getToday();
  return {
    start: addMonths(date, -1),
    end: date
  };
};

export const getQuarterPeriod = (dateEnd: Date = getToday()) => {
  return {
    start: addMonths(dateEnd, -3),
    end: dateEnd
  };
};

/**
 * Вывод Времени из timestamp в форматированную строку
 * @param time в timestamp
 * @returns (HH:)MM:SS
 */
export const getFormatedTime = (time: number): string => {
  if (typeof time !== "number" || time === NaN || !time) {
    return "00:00";
  }

  let hour = Math.floor(time / 3600);
  if (hour && hour < 10) {
    hour = "0" + hour;
  }
  let min = (time % 3600) / 60;
  min = Math.floor(min);
  if (min < 10) {
    min = "0" + min;
  }
  let sec = time % 60;
  if (sec < 10) {
    sec = "0" + sec;
  }
  return `${hour ? hour + ":" : ""}${min}:${sec}`;
};

/**
 * Установит в дату переданные часы и минуты.
 *
 * @param {Date} date Дата, которую необходимо изменить.
 * @param {string} time Время __:__:__.
 * @param {boolean} [isLower] Флаг, который показывает, что нужно добавить 59 секунд.
 */
export const setTime = (date: Date, time: string, isLower?: boolean) => {
  if (!isDate(date) || !time) return date;

  let result = date;
  let [hours, minutes, seconds = isLower ? "59" : "00"] = time.split(":");

  if (isFinite(+hours)) {
    result = setHours(result, +hours);
  }

  if (isFinite(+minutes)) {
    result = setMinutes(result, +minutes);
  }

  if (isFinite(+seconds)) {
    result = setSeconds(result, +seconds);
  }

  return result;
};

/**
 * Вывод Времени из timestamp в форматированную строку для превью обращения
 * @param time в timestamp
 * @returns MM:SS || ДЕНЬ МЕС
 */
export const getFormatedForAppealPrewiewTime = (time: number): string => {
  if (typeof time !== "number" || time === NaN || !time) {
    return "";
  }

  var result,
    month = [
      "ЯНВ",
      "ФЕВ",
      "МАР",
      "АПР",
      "МАЙ",
      "ИЮН",
      "ИЮЛ",
      "АВГ",
      "СЕН",
      "ОКТ",
      "НОЯ",
      "ДЕК"
    ];

  var today = new Date();
  var date = new Date();
  date.setTime(time);

  if (
    date.getYear() + "_" + date.getMonth() + "_" + date.getDay() ==
    today.getYear() + "_" + today.getMonth() + "_" + today.getDay()
  ) {
    result =
      date.getHours() +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes();
  } else {
    result = date.getDate() + " " + month[date.getMonth()];
  }

  return result;
};

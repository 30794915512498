import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createSlice,
  createAction,
  PayloadAction,
  bindActionCreators
} from "@reduxjs/toolkit";

import { ChartData, ChartsFilters } from "../Charts";
import initialState from "../../../ReactCore/store/initialState";

const NAMESPACE = "dashboardCharts/";

export const GET_CHART_AHT = `${NAMESPACE}GET_CHART_AHT`;
export const GET_CHART_APPEALS = `${NAMESPACE}GET_CHART_APPEALS`;
export const GET_CHART_CSI = `${NAMESPACE}GET_CHART_CSI`;
export const GET_CHART_LCR = `${NAMESPACE}GET_CHART_LCR`;
export const GET_CHART_ORT = `${NAMESPACE}GET_CHART_ORT`;
export const GET_CHART_SL = `${NAMESPACE}GET_CHART_SL`;

export const getChartAht = createAction<{ filters: ChartsFilters }>(
  GET_CHART_AHT
);
export const getChartAppeals = createAction<{ filters: ChartsFilters }>(
  GET_CHART_APPEALS
);
export const getChartCsi = createAction<{ filters: ChartsFilters }>(
  GET_CHART_CSI
);
export const getChartLcr = createAction<{ filters: ChartsFilters }>(
  GET_CHART_LCR
);
export const getChartOrt = createAction<{ filters: ChartsFilters }>(
  GET_CHART_ORT
);
export const getChartLs = createAction<{ filters: ChartsFilters }>(
  GET_CHART_SL
);

const ChartsSlice = createSlice({
  name: "charts",
  initialState: initialState.Charts,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      state.loading = [...state.loading, action.payload];
    },
    finishLoading(state, action: PayloadAction<string>) {
      const currentLoadStatus = [...state.loading];
      currentLoadStatus.splice(state.loading.indexOf(action.payload));
      state.loading = currentLoadStatus;
    },
    setChart(state, action: PayloadAction<{ [key: string]: ChartData }>) {
      state.charts = { ...state.charts, ...action.payload };
    },
    resetCharts(state) {
      state.charts = {};
    }
  }
});

export const actions = {
  ...ChartsSlice.actions,
  getChartAht,
  getChartAppeals,
  getChartCsi,
  getChartLcr,
  getChartOrt,
  getChartLs
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
};

export default ChartsSlice.reducer;

import React, { FC } from "react";
import { connect } from "react-redux";
import { sendTransferData } from "../Actions/TransferAppealModal.actionCreators";
import MoveAppealModalProvider from "./MoveAppealModal.provider";

/**
 * Модель свойств, полученных из store.
 *
 * @prop {IAsyncData} asyncAvailableHotKeysData Контейнер с данными МО трансфера,
 * которые получены асинхронно.
 */
interface IStateProps {}

/**
 * Экшены.
 */
interface IDispatchProps {
  sendTransferData: any;
}

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {number} appealId Идентификатор обращения.
 * @prop {Function} close Колбэк на закрытие модального окна.
 * @prop {Function} postAction Колбэк для выполнения после нажатия на "Submit".
 */
interface IOwnProps {
  transferFromChat: boolean;
  appealId: string;
  onClose: () => void;
  afterConfirm: () => void;
}

type TProps = IOwnProps & IDispatchProps & IStateProps;

const TransferAppealModalProvider: FC<TProps> = (props) => {
  const {
    appealId,
    onClose,
    sendTransferData,
    afterConfirm,
    transferFromChat
  } = props;

  /**
   * Обработка нажатия на кнопку "Продолжить".
   * @param data
   */
  const handleConfirm = (data: any) => {
    sendTransferData({ ...data, transferFromChat });
  };

  return (
    <MoveAppealModalProvider
      close={onClose}
      appealId={appealId}
      header="Передать обращение?"
      onConfirm={handleConfirm}
      afterConfirm={afterConfirm}
    />
  );
};

const mapDispatchToProps = {
  sendTransferData
};

export default connect(null, mapDispatchToProps)(TransferAppealModalProvider);

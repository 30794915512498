import React, { FC, memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, isEmpty, Tooltip } from "@omnichat/arm_ui_kit";
import Clear from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

import { Checkbox, FormFieldConnector, Input } from "../../../../Components";
import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";
import { Role, rightsDTO, IRoleFormDataProp } from "../../Roles";
import * as selectors from "../../Store/Roles.selector";
import { mapEditRoleFormToDto, mapEditRightsFormToDto } from "../../utils";

import { useActions } from "../../Store/Roles.reducer";
import { schema, defaultValues, IFormData } from "./RoleEdit.schema";

const checkboxLabelSx = {
  display: "flex",
  justifyContent: "space-between"
};

interface Props {
  editMode?: boolean;
  formData?: {
    role?: Role;
    rights?: rightsDTO;
  };
  onSave: (formData: IRoleFormDataProp) => void;
  onCancel: () => void;
  onFormHasDataChanged: (value: boolean) => void;
}

const RoleEdit: FC<Props> = ({
  editMode = false,
  formData,
  onCancel,
  onSave,
  onFormHasDataChanged
}) => {
  const actions = useActions();
  const prevCountRef = useRef(null);

  const methods = useForm<IFormData, any, IFormData>({
    defaultValues,
    reValidateMode: "onBlur",
    resolver: yupResolver(schema)
  });
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState,
    setError,
    clearErrors
  } = methods;

  const { isDirty } = formState;

  const personDashboard = watch("rights.personDashboard");
  const systemDashboard = watch("rights.systemDashboard");
  const monitoring = watch("rights.monitoring");

  const dataSendHandling = useSelector(selectors.getDataSendHandling);
  const errorMessage = useSelector(selectors.getErrorMessage);

  useEffect(() => {
    return () => {
      onFormHasDataChanged?.(false);
    };
  }, []);

  useEffect(() => {
    if (errorMessage) {
      onFormHasDataChanged(true);
      setError(
        "role.name",
        { type: "custom", message: errorMessage },
        { shouldFocus: true }
      );
    }
  }, [errorMessage]);

  useEffect(() => {
    if (!dataSendHandling) {
      if (formData) {
        if (JSON.stringify(prevCountRef.current) !== JSON.stringify(formData)) {
          reset(formData);
        }
        prevCountRef.current = formData;
      } else {
        reset(defaultValues);
      }
    }
  }, [formData]);

  useEffect(() => {
    onFormHasDataChanged?.(isDirty);
  }, [isDirty]);

  const handleSave = (data) => {
    onFormHasDataChanged(false);
    actions.setErrorMessage("");
    onSave({
      role:
        formData?.role?.name !== data.role.name
          ? mapEditRoleFormToDto(data.role)
          : null,
      rights: mapEditRightsFormToDto(data.rights)
    });
  };

  const handleCancel = () => {
    // do not optimize!
    onCancel();
  };

  const onChangeRoleName = () => {
    onFormHasDataChanged(true);
    clearErrors(["role.name"]);
    actions.setErrorMessage("");
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h2"
          style={{ lineHeight: 2.2 }}
          onClick={() => onFormHasDataChanged(true)}
        >
          {editMode ? "Редактирование роли" : "Создание роли"}
        </Typography>
        <Tooltip content="Отменить" position="bottom" modifiers={{ offset: 5 }}>
          <IconButton
            onClick={handleCancel}
            aria-label="settings"
            color="primary"
            sx={{
              cursor: "pointer"
            }}
          >
            <Clear
              sx={{
                width: "24px",
                height: "24px"
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
      <FormProvider {...methods}>
        <Grid container rowSpacing={2} columnSpacing={2} marginTop={1}>
          <Grid item alignItems="center" xs={12}>
            <FormFieldConnector
              name="role.name"
              label="Название Роли"
              required
              Component={(p, { error }) => (
                <Input
                  {...p}
                  isError={!isEmpty(error)}
                  placeholder="Не указано"
                  onChange={(value) => {
                    onChangeRoleName();
                    p.onChange(value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item alignItems="center" xs={12} marginTop={2}>
            <Typography variant="h3">Права доступа:</Typography>
          </Grid>

          <Grid item alignItems="center" xs={12} marginTop={1} marginBottom={1}>
            <Typography variant="h4">Чат</Typography>
          </Grid>

          <Grid item alignItems="center" xs={12} marginLeft={4}>
            <FormFieldConnector
              name="rights.agent"
              Component={(p) => (
                <Checkbox
                  {...p}
                  type="slider"
                  caption="Обращения – Обмен сообщениями, перевод и закрытие обращений"
                  labelSx={checkboxLabelSx}
                  checked={p.value}
                  id="agent"
                />
              )}
            />
          </Grid>

          <Grid item alignItems="center" xs={12} marginTop={3}>
            <Typography variant="h4">Дашборд</Typography>
          </Grid>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            marginTop={1}
            marginLeft={4}
          >
            <Grid item alignItems="center" xs={12}>
              Статистика
            </Grid>
            <Grid container rowSpacing={1} marginTop={2} marginLeft={6}>
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="rights.personDashboard"
                  Component={(p) => (
                    <Checkbox
                      {...p}
                      type="slider"
                      caption="Просмотр персональной статистики"
                      labelSx={checkboxLabelSx}
                      checked={p.value}
                      onChange={(e) => {
                        if (systemDashboard) {
                          setValue("rights.systemDashboard", false);
                        }
                        p.onChange(e);
                      }}
                      id="rights.personDashboard"
                      actionText="Статистика по обращениям сотрудника"
                    />
                  )}
                />
              </Grid>
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="rights.systemDashboard"
                  Component={(p) => (
                    <Checkbox
                      {...p}
                      type="slider"
                      caption="Просмотр общей статистики"
                      labelSx={checkboxLabelSx}
                      checked={p.value}
                      onChange={(e) => {
                        if (personDashboard) {
                          setValue("rights.personDashboard", false);
                        }
                        p.onChange(e);
                      }}
                      id="rights.systemDashboard"
                      actionText="Статистика по всем обращениям"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={1} marginTop={2} marginLeft={10}>
              <Grid item alignItems="center" xs={12}>
                <FormFieldConnector
                  name="rights.monitoring"
                  Component={(p) => (
                    <Checkbox
                      {...p}
                      type="slider"
                      disabled={!systemDashboard}
                      caption="Мониторинг нагрузки"
                      labelSx={checkboxLabelSx}
                      checked={p.value}
                      id="rights.monitoring"
                    />
                  )}
                />
              </Grid>

              <Grid item alignItems="center" xs={12} marginLeft={4}>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.skillLimit"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard || !monitoring}
                        caption="Управление нагрузкой на группу"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.skillLimit"
                      />
                    )}
                  />
                </Grid>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.agentLimit"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard || !monitoring}
                        caption="Управление нагрузкой на сотрудника"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.agentLimit"
                      />
                    )}
                  />
                </Grid>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.userGroups"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard || !monitoring}
                        caption="Редактирование групп сотрудника"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.userGroups"
                      />
                    )}
                  />
                </Grid>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.forcedLogout"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard || !monitoring}
                        caption="Принудительное завершение сессии сотрудника"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.forcedLogout"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                alignItems="center"
                xs={12}
                marginTop={1}
                style={{ opacity: systemDashboard ? 1 : 0.5 }}
              >
                Обращения
              </Grid>
              <Grid container rowSpacing={1} marginTop={2} marginLeft={4}>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.appealClose"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard}
                        caption="Закрытие обращения"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.appealClose"
                      />
                    )}
                  />
                </Grid>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.appealTransfer"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard}
                        caption="Перевод обращения"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.appealTransfer"
                      />
                    )}
                  />
                </Grid>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.appealReopen"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard}
                        caption="Открытие ранее закрытого обращения"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.appealReopen"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                alignItems="center"
                xs={12}
                marginTop={1}
                style={{ opacity: systemDashboard ? 1 : 0.5 }}
              >
                Отчетность
              </Grid>
              <Grid container rowSpacing={1} marginTop={2} marginLeft={4}>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.excel"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard}
                        caption="Выгрузка отчетов в Excel"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.excel"
                      />
                    )}
                  />
                </Grid>
                <Grid item alignItems="center" xs={12}>
                  <FormFieldConnector
                    name="rights.reportAppeals"
                    Component={(p) => (
                      <Checkbox
                        {...p}
                        type="slider"
                        disabled={!systemDashboard}
                        caption="Выгрузка обращений"
                        labelSx={checkboxLabelSx}
                        checked={p.value}
                        id="rights.reportAppeals"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item alignItems="center" xs={12}>
            <Typography variant="h4" marginTop={3} marginBottom={1}>
              Настройки
            </Typography>
          </Grid>
          <Grid container rowSpacing={1} marginTop={2} marginLeft={6}>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.templates"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Шаблоны – Создание, редактирование и удаление"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.templates"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.classifier"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Конструктор форм – Создание, редактирование и удаление"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.classifier"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.createUsers"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Профили сотрудников – Создание, редактирование и удаление"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.createUsers"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.skills"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Группы – Создание, редактирование и удаление"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.skills"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.channels"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Каналы – Создание, редактирование и удаление"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.channels"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.autoreplies"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Автоответы – Создание, редактирование и удаление автоответов"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.autoreplies"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.bots"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Чат-боты – Создание, редактирование и удаление"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.bots"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.roles"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Роли – Создание, редактирование и удаление ролей"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.roles"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.vip"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Vip – Просмотр, редактирование распределения обращений vip-пользователей, vip-скиллгрупп"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.vip"
                  />
                )}
              />
            </Grid>
            <Grid item alignItems="center" xs={12}>
              <FormFieldConnector
                name="rights.systemSettings"
                Component={(p) => (
                  <Checkbox
                    {...p}
                    type="slider"
                    caption="Системные настройки – Просмотр, редактирование"
                    labelSx={checkboxLabelSx}
                    checked={p.value}
                    id="rights.systemSettings"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={1} columnSpacing={2} mt={2} ml={0} mr={1}>
            <Grid item xs={6}>
              <Button
                type="default"
                iconColor="white"
                theme="red"
                onClick={onCancel}
                text="Отменить"
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end"
                }}
              >
                <Button
                  type="default"
                  disabled={dataSendHandling || !isDirty}
                  theme={dataSendHandling || !isDirty ? "disabled" : "green"}
                  onClick={handleSubmit(
                    (data) =>
                      handleSave({
                        ...(formData || {}),
                        ...data
                      }),
                    ReactHookFormErrorHandler
                  )}
                  text="Сохранить"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default memo(RoleEdit);

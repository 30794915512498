import React, { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import { isEmpty, Button } from "@omnichat/arm_ui_kit";
import ModalWindow from "../../../../Components/ModalWindow";

import { AgentData } from "../../Monitoring";
import AgentItem from "./AgentItem";

import { useActions } from "../../Store/Monitoring.reducer";
import * as monitoringSelectors from "../../Store/Monitoring.selector";

import * as s from "./styles.module.scss";

interface AgentListProps {
  agents?: AgentData[];
  onManageLoad: (id: number) => void;
  onSelect: (id: number) => void;
  hasQuery: boolean;
  currentAgentId?: number;
  editable: boolean;
}

const AgentList: FC<AgentListProps> = ({
  editable,
  agents = [],
  onManageLoad,
  onSelect,
  hasQuery,
  currentAgentId
}) => {
  const [openTerminateModalWindow, toggleTerminateModalWindow] = useState<
    number | null
  >(null);

  const actions = useActions();

  const isLoadingTerminate = useSelector(monitoringSelectors.loadingTerminate);

  const terminateUser = useCallback(() => {
    actions.sendTerminate({
      userId: openTerminateModalWindow,
      cb: () => toggleTerminateModalWindow(null)
    });
  }, [agents, openTerminateModalWindow, toggleTerminateModalWindow]);

  return (
    <div className={s["agentsWrap"]}>
      {isEmpty(agents) && (
        <div className={s["agentsEmpty"]}>
          {hasQuery ? "нет совпадений" : "нет агентов в группе"}
        </div>
      )}

      {!isEmpty(agents) && (
        <div>
          {agents.map((agent, i) => (
            <AgentItem
              key={`${i}_${agent.id}`}
              agent={agent}
              current={currentAgentId === agent.id}
              onSelect={onSelect}
              onEdit={onManageLoad}
              onTerminate={toggleTerminateModalWindow}
              editable={editable}
            />
          ))}
        </div>
      )}
      <ModalWindow
        isButtonClose
        open={!!openTerminateModalWindow}
        onCloseModal={() => toggleTerminateModalWindow(null)}
      >
        <div className={s["modalContainer"]}>
          <header>Завершение сессии сотрудника</header>
          <div>
            Сессия сотрудника будет завершена. <br />
            Продолжить?
          </div>
          <div className={s["modalContainerButtons"]}>
            <Button
              type="default"
              theme="red"
              onClick={() => toggleTerminateModalWindow(null)}
              text="Отменить"
            />
            <Button
              type="default"
              disabled={isLoadingTerminate}
              theme="green"
              onClick={terminateUser}
              text="Продолжить"
            />
          </div>
        </div>
      </ModalWindow>
    </div>
  );
};

export default AgentList;

import React, { FC, useReducer, useState, createRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Avatar,
  SwitcherContainer,
  ColorPicker,
  isEmpty,
  FileUploadInput,
  FormField
} from "@omnichat/arm_ui_kit";
import styled from "styled-components";
import PrechatOption from "./PrechatOption";
import ChannelSettingsModalReducer, {
  EReducerActions,
  EWidgetSchedule,
  TReducerState
} from "./ChannelSettingsModalReducer";
import { bytesToMegabytes } from "../../../arm/Constructors/Utils";
import { timeZonesOptions } from "../consts";
import Informer from "../../../arm/Wrappers/Informer";
import {
  pushFiles,
  sendFile,
  cleanFileList
} from "../../../ReactFeatures/FileStorage/fileStorage.actions";
import {
  Input,
  Checkbox,
  Radio,
  Select,
  Autocomplete
} from "../../../Components";
import ModalWindow from "../../../Components/ModalWindow";
import TextArea from "../../../Components/TextArea/TextArea";
import { StoreState } from "frontend/src/ReactCore/store/types";

const ModalRoot = styled.div`
  width: 600px;
`;

const ErrorModalRoot = styled.div`
  font-size: 16px;
  width: 350px;
  font-weight: 700;
  line-height: 130%;
  padding: 15px;
  text-align: center;
`;

const Header = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #2a323f;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  padding-bottom: 15px;
`;

const ModalSeparator = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
  padding: 5px 0;
  color: #2a323f !important;
  margin: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  & label {
    color: #2a323f !important;
  }
`;

const FieldRoot = styled.div``;

const BacklogSwitchWrapper = styled.div`
  margin: 16px 0;
`;

const PrechatFieldsRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const FielsFieldsRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const StylesFieldsRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const TwoColumnsRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    flex-grow: 1;
  }
  & > *:nth-child(even) {
    margin-left: 20px;
  }
`;

const Column = styled.div`
  width: 50%;
`;

const ThemeFieldsRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const TriggerMessageFieldsRoot = styled.div`
  display: flex;
  flex-direction: column;
`;
const AvatarOperatorFieldRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const WidgetShedule = styled.div``;

const ActionButtons = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 20px;
  & > button {
    margin-right: 10px;
  }
`;

const IntegrationCodeContainer = styled.div`
  display: grid;
  grid-row-gap: 10px;
`;

const CopyButtonContainer = styled.div`
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
`;

const TextAreaContainer = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 5;
`;

const ShowWidgetCheckboxContainer = styled.div`
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 4;
`;

const ErrorText = styled.div`
  display: block;
  color: red;
`;

const CheckBoxField = SwitcherContainer(Checkbox);
const RadioField = SwitcherContainer(Radio);

const getChannelScript = (channelId: string) => {
  const domain = window.location.hostname;

  return `((d)=>{if(window.ocWidgetOptions){return};var s = d.createElement("script");s.type = "text/javascript";s.async = true;s.src ="https://${domain}/widget/bundle.js?rnd="+Math.random();d.getElementsByTagName("head")[0].appendChild(s);window.ocWidgetOptions = { channel_id: ${channelId} };})(document)`;
};

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {TReducerState} defaultState Настройки по умолчанию.
 * @prop {Array<{ value: string; label: string }>} fileExtensions Расширения файлов.
 * @prop {string} name Наименование канала.
 * @prop {string} channelId Идентификатор канала.
 * @prop {(name: string, settings: TReducerState) => void} onSave Колбэк на сохранение.
 * @prop {() => void} onDelete Колбэк на удаление.
 */
interface IOwnProps {
  defaultState: TReducerState;
  fileExtensions: Array<{ value: string; label: string }>;
  name: string;
  channelId: string;
  onSave: (name: string, settings: TReducerState) => void;
  onDelete: () => void;
}

interface IStateProps {
  fsFiles: File[];
}

interface IDispatchProps {
  pushFiles;
  cleanFileList;
  sendFile;
}

type ChannelSettingsModalProps = IOwnProps & IStateProps & IDispatchProps;

const ChannelSettingsModal: FC<ChannelSettingsModalProps> = (props) => {
  const [name, setName] = React.useState(props.name);

  const [
    {
      prechats,
      bar,
      schedule,
      files,
      appearance,
      enabled,
      fallbackOperatorAvatar,
      backlogEnabled
    },
    dispatch
  ] = useReducer(ChannelSettingsModalReducer, props.defaultState);

  const [sheduleState, changeSheduleState] = useState(
    setInitialShedule(props.defaultState?.schedule)
  );
  const codeRef: React.RefObject<TextArea> = createRef<TextArea>();
  const [triggerText] = useState(bar?.triggerMessage.text);
  const [triggerError, setTriggerError] = useState(false);
  const [triggerDelayError, setTriggerDelayError] = useState({
    isError: false,
    errorText: ""
  });
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [scheduleError, setScheduleError] = useState({
    isError: false,
    errorText: ""
  });

  useEffect(() => {
    const { fsFiles, sendFile } = props;
    if (!isEmpty(fsFiles)) {
      fsFiles.forEach((file) => {
        !file.sended && !file.pending && sendFile(file.uuid, updateAgentAvatar);
      });
    }
  }, [props.fsFiles]);

  const updateAgentAvatar = ({ data }) => {
    const ext = data.originalName
      .split(".")
      .pop()
      .toLowerCase();
    const fileName = `${data.uuid}.${ext}`;
    dispatch({ type: EReducerActions.DefaultOperatorAvatar, data: fileName });
  };

  const fileChangeHandler = (e, file) => {
    const fileName = file[0].name;
    const fileSize = bytesToMegabytes(file[0].size);
    const avatarExt = ["jpg", "jpeg", "png"];
    const ext = fileName
      .split(".")
      .pop()
      .toLowerCase();
    const isValidExt = avatarExt.find((item) => item === ext);
    const isValidSize = fileSize < MAX_AVATAR_SIZE_MB;
    if (!isValidExt) {
      new Informer("Недопустимый формат файла", 3000).show();
      return;
    }
    if (!isValidSize) {
      new Informer(
        `Размер файла должен быть менее ${MAX_AVATAR_SIZE_MB} Мб`,
        3000
      ).show();
      return;
    }
    props.pushFiles(file);
  };

  /**
   * Прокинет дефолтное значение для радио-баттона с расписанием виджета.
   *
   * @param schedule Данные о времени отображения виджета.
   */
  function setInitialShedule(schedule) {
    return schedule?.to === 24 && schedule?.from === 0
      ? EWidgetSchedule.around_the_clock
      : EWidgetSchedule.on_schedule;
  }

  /**
   * Обработчик изменения поля ввода.
   *
   * @param {EReducerActions} actionType Тип экшена, который надо передать.
   */
  const handleInputChange = (actionType: EReducerActions) => (
    value: string
  ) => {
    dispatch({
      type: actionType,
      data: value
    });
  };

  /**
   * Обработчик выбора положения чек-бокса.
   *
   * @param {EReducerActions} actionType Тип экшена, который надо передать.
   * @param {boolean} data Флаг true/false.
   */
  const handleCheckboxChange = (
    actionType: EReducerActions,
    data: boolean
  ) => () => {
    dispatch({
      type: actionType,
      data
    });
  };

  /**
   * Проверка правильности заполнения полей ScheduleFrom ScheduleTo
   * @return
   */
  const isScheduleError = () => {
    if (sheduleState === EWidgetSchedule.on_schedule) {
      if (schedule.from >= schedule.to) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isScheduleErrorText = () => {
    if (isScheduleError()) {
      return "Выбран некорректный временной промежуток";
    }
    return "";
  };

  /**
   * Проверка правильности заполнения поля Delay
   * триггерного сообщения
   * @returns
   */
  const isTriggerDelayError = () => {
    if (typeof bar.triggerMessage.delay === "number") {
      return bar.triggerMessage.delay < 0;
    }
    if (isEmpty(bar.triggerMessage.delay)) {
      return true;
    }
    if (Number.isNaN(+bar.triggerMessage.delay)) {
      return true;
    }
    if (+bar.triggerMessage.delay < 0) {
      return true;
    }
    return false;
  };
  /**
   * Возвращает текст ошибки для поля Delay
   * триггерного сообщения
   * @returns
   */
  const triggerDelayErrorText = () => {
    if (isTriggerDelayError()) {
      if (
        Number.isNaN(+bar.triggerMessage.delay) ||
        +bar.triggerMessage.delay < 0
      ) {
        return "Значение должно быть целым числом не меньше 0";
      }
      if (isEmpty(bar.triggerMessage.delay)) {
        return "Поле не может быть пустым";
      }
    }
    return "";
  };
  /**
   * Обработчик сохранения изменений.
   */
  const handleSaveChanges = function() {
    if (bar?.triggerMessage.enabled) {
      if (isEmpty(bar.triggerMessage.text) || isTriggerDelayError()) {
        setTriggerError(isEmpty(bar.triggerMessage.text));
        setTriggerDelayError({
          isError: isTriggerDelayError(),
          errorText: triggerDelayErrorText()
        });
        setScheduleError({
          isError: isScheduleError(),
          errorText: isScheduleErrorText()
        });
        setOpenErrorModal(true);
        return;
      }
    }

    if (isScheduleError()) {
      setScheduleError({
        isError: isScheduleError(),
        errorText: isScheduleErrorText()
      });
      setOpenErrorModal(true);
      return;
    }

    const channelSettings = !isEmpty(props.defaultState)
      ? {
          prechats,
          bar,
          schedule,
          files,
          appearance,
          enabled,
          fallbackOperatorAvatar,
          backlogEnabled
        }
      : null;
    props.cleanFileList();
    props.onSave(name, channelSettings);
  };

  /**
   * Обработчик для копирования кода встраивания.
   */
  const handleCopyCode = async function() {
    if (codeRef && codeRef.current?.props?.initialValue) {
      const text = codeRef.current.props.initialValue;

      await navigator.clipboard.writeText(text);
    }
  };

  const scheduleFromOptions = [...Array(24)].map((_, i) => ({
    value: i,
    label: i >= 0 && i <= 9 ? "0" + i.toString() : i.toString()
  }));

  const scheduleToOptions = [...Array(25)].map((_, i) => ({
    value: i,
    label: i >= 0 && i <= 9 ? "0" + i.toString() : i.toString()
  }));

  const barShapeOptions = [
    { value: "rounded", label: "Овальная" },
    { value: "cilcular", label: "Круглая" }
  ];

  const chatShapeOptions = [
    { value: "rounded", label: "Округлая" },
    { value: "square", label: "Прямоугольная" }
  ];

  const fieldAndBtnShapeOptions = [
    { value: "rounded", label: "Овальная" },
    { value: "cilcular", label: "Круглая" },
    { value: "geometric", label: "Плоская" }
  ];

  const colorThemeOptions = [
    { value: "white", label: "Светлая" },
    { value: "black", label: "Темная" }
  ];

  return (
    <>
      {(triggerDelayError || triggerError) && (
        <ModalWindow
          open={openErrorModal}
          onCloseModal={() => {
            setOpenErrorModal(false);
          }}
          isButtonClose
        >
          <ErrorModalRoot>
            Перед сохранением необходимо корректно заполнить поля
          </ErrorModalRoot>
        </ModalWindow>
      )}
      <ModalRoot>
        <Header>Редактирование канала</Header>

        <Input
          label="Название"
          required
          placeholder="Название канала"
          value={name}
          onChange={(e) => setName(e)}
        />

        {!isEmpty(props.defaultState) && (
          <>
            <ModalSeparator>Код встраивания</ModalSeparator>

            <IntegrationCodeContainer>
              <CopyButtonContainer>
                <Button
                  text="Скопировать"
                  type="fill"
                  onClick={handleCopyCode}
                  extraClass={[]}
                />
              </CopyButtonContainer>

              <TextAreaContainer>
                <TextArea
                  ref={codeRef}
                  focus={null}
                  initialValue={getChannelScript(props.channelId)}
                />
              </TextAreaContainer>

              <ShowWidgetCheckboxContainer>
                <CheckBoxField
                  caption="Отобразить виджет"
                  captionPosition="after"
                  type="slider"
                  checked={enabled}
                  onChange={handleCheckboxChange(
                    EReducerActions.ShowWidget,
                    !enabled
                  )}
                />
              </ShowWidgetCheckboxContainer>
            </IntegrationCodeContainer>

            <ModalSeparator>Распределение обращений</ModalSeparator>

            <BacklogSwitchWrapper>
              <CheckBoxField
                caption="Помещать обращение в backlog"
                captionPosition="after"
                type="slider"
                checked={backlogEnabled}
                onChange={handleCheckboxChange(
                  EReducerActions.BacklogEnabled,
                  !backlogEnabled
                )}
              />
            </BacklogSwitchWrapper>

            <ModalSeparator>Время отображения виджета</ModalSeparator>

            <WidgetShedule>
              {/* <CheckBoxField
                type="slider"
                caption="Отображать виджет, только если операторы online"
                checked={schedule?.showWidgetOnlyIfAgentsOnline}
                onChange={handleCheckboxChange(
                  EReducerActions.ScheduleShowWidgetOnlyIfAgentsOnline,
                  !schedule?.showWidgetOnlyIfAgentsOnline
                )}
              /> */}
              <RadioField
                name="widget-shedule"
                caption="Круглосуточно"
                checked={sheduleState === EWidgetSchedule.around_the_clock}
                onClick={() => {
                  dispatch({
                    type: EReducerActions.ScheduleFrom,
                    data: 0
                  });
                  dispatch({
                    type: EReducerActions.ScheduleTo,
                    data: 24
                  });
                  changeSheduleState(EWidgetSchedule.around_the_clock);
                  setScheduleError({ isError: false, errorText: "" });
                }}
              />
              <RadioField
                name="widget-shedule"
                caption="По расписанию"
                checked={sheduleState === EWidgetSchedule.on_schedule}
                onClick={() => {
                  setScheduleError({ isError: false, errorText: "" });
                  changeSheduleState(EWidgetSchedule.on_schedule);
                }}
              />
              {sheduleState === EWidgetSchedule.on_schedule && (
                <>
                  <FormField label="Часовой пояс">
                    <Autocomplete
                      isSearchable
                      placeholder="Не указана"
                      options={timeZonesOptions}
                      selected={timeZonesOptions.filter(
                        (o) => o.value === schedule?.timeZone
                      )}
                      onSelectOption={([val]) =>
                        dispatch({
                          type: EReducerActions.ScheduleTimeZone,
                          data: val.value
                        })
                      }
                    />
                  </FormField>
                  <TwoColumnsRoot>
                    <Column>
                      <FormField label="Отображать с">
                        <Select
                          options={scheduleFromOptions}
                          selected={scheduleFromOptions.filter(
                            (o) => o.value === schedule?.from
                          )}
                          onSelectOption={([val]) => {
                            setScheduleError({ isError: false, errorText: "" });
                            dispatch({
                              type: EReducerActions.ScheduleFrom,
                              data: val.value
                            });
                          }}
                        />
                      </FormField>
                    </Column>
                    <Column>
                      <FormField label="Отображать по">
                        <Select
                          options={scheduleToOptions}
                          selected={scheduleToOptions.filter(
                            (o) => o.value === schedule?.to
                          )}
                          onSelectOption={([val]) => {
                            setScheduleError({ isError: false, errorText: "" });
                            dispatch({
                              type: EReducerActions.ScheduleTo,
                              data: val.value
                            });
                          }}
                        />
                      </FormField>
                    </Column>
                  </TwoColumnsRoot>
                  {scheduleError.isError && (
                    <ErrorText>{scheduleError.errorText}</ErrorText>
                  )}
                </>
              )}
            </WidgetShedule>

            <ModalSeparator>
              <CheckBoxField
                type="slider"
                caption="Пречат поля"
                captionPosition="after"
                checked={prechats?.enabled}
                onChange={handleCheckboxChange(
                  EReducerActions.PrechatsIsEnabled,
                  !prechats?.enabled
                )}
              />
            </ModalSeparator>

            {!!prechats?.enabled && (
              <PrechatFieldsRoot>
                <PrechatOption
                  fieldLabel="«ОТДЕЛ»"
                  groupsValue={prechats?.skillGroup}
                  enabledOnChange={handleCheckboxChange(
                    EReducerActions.PrechatsFieldsSkillIdIsEnabled,
                    !prechats?.skillGroup?.enabled
                  )}
                  requiredOnChange={handleCheckboxChange(
                    EReducerActions.PrechatsFieldsSkillIdIsNecessary,
                    !prechats?.skillGroup?.required
                  )}
                  labelOnChange={handleInputChange(
                    EReducerActions.PrechatsFieldsSkillIdLabel
                  )}
                />

                <PrechatOption
                  fieldLabel="«ИМЯ»"
                  groupsValue={prechats?.fullName}
                  enabledOnChange={handleCheckboxChange(
                    EReducerActions.PrechatsFieldsFullNameIsEnabled,
                    !prechats?.fullName?.enabled
                  )}
                  requiredOnChange={handleCheckboxChange(
                    EReducerActions.PrechatsFieldsFullNameIsNecessary,
                    !prechats?.fullName?.required
                  )}
                  labelOnChange={handleInputChange(
                    EReducerActions.PrechatsFieldsFullNameLabel
                  )}
                />

                <PrechatOption
                  fieldLabel="«ТЕЛЕФОН»"
                  groupsValue={prechats?.phone}
                  enabledOnChange={handleCheckboxChange(
                    EReducerActions.PrechatsFieldsPhoneIsEnabled,
                    !prechats?.phone?.enabled
                  )}
                  requiredOnChange={handleCheckboxChange(
                    EReducerActions.PrechatsFieldsPhoneIsNecessary,
                    !prechats?.phone?.required
                  )}
                  labelOnChange={handleInputChange(
                    EReducerActions.PrechatsFieldsPhoneLabel
                  )}
                />
              </PrechatFieldsRoot>
            )}

            <ModalSeparator>
              <CheckBoxField
                type="slider"
                caption="Текст бара"
                captionPosition="after"
                checked={bar?.text?.enabled}
                onChange={handleCheckboxChange(
                  EReducerActions.BarTextEnabled,
                  !bar?.text?.enabled
                )}
              />
            </ModalSeparator>

            {!!bar?.text?.enabled && (
              <FieldRoot>
                <Input
                  label="Текст"
                  placeholder="Текст бара"
                  value={bar?.text?.value}
                  onChange={handleInputChange(EReducerActions.BarTextValue)}
                />
              </FieldRoot>
            )}
            <TwoColumnsRoot>
              <FormField label="Форма бара">
                <Select
                  options={barShapeOptions}
                  selected={barShapeOptions.filter(
                    (o) => o.value === bar?.shape
                  )}
                  onSelectOption={([val]) =>
                    dispatch({
                      type: EReducerActions.BarShape,
                      data: val.value
                    })
                  }
                />
              </FormField>
            </TwoColumnsRoot>

            <ModalSeparator>
              <CheckBoxField
                type="slider"
                caption="Триггерное сообщение"
                captionPosition="after"
                checked={bar?.triggerMessage?.enabled}
                onChange={handleCheckboxChange(
                  EReducerActions.BarTriggerMessageIsEnabled,
                  !bar?.triggerMessage?.enabled
                )}
              />
            </ModalSeparator>
            <TriggerMessageFieldsRoot>
              {!!bar?.triggerMessage?.enabled && (
                <>
                  <FieldRoot>
                    <FormField label="Текст сообщения" required>
                      <TextArea
                        initialValue={triggerText}
                        isError={triggerError}
                        actionText={
                          triggerError && "Сообщение не может быть пустым"
                        }
                        onChange={(value) => {
                          setTriggerError(false);
                          dispatch({
                            type: EReducerActions.BarTriggerMessageText,
                            data: value.text
                          });
                        }}
                      />
                    </FormField>
                  </FieldRoot>
                  <Input
                    label="Отображать с"
                    placeholder="Кол-во секунд"
                    value={bar?.triggerMessage?.delay.toString()}
                    required
                    isError={triggerDelayError.isError}
                    actionText={
                      triggerDelayError.isError && triggerDelayError.errorText
                    }
                    onChange={(value) => {
                      setTriggerDelayError({ isError: false, errorText: "" });
                      dispatch({
                        type: EReducerActions.BarTriggerMessageDelay,
                        data: value
                      });
                    }}
                  />
                </>
              )}
            </TriggerMessageFieldsRoot>
            <ModalSeparator>Аватар оператора по умолчанию</ModalSeparator>
            <AvatarOperatorFieldRoot>
              <div style={{ width: fallbackOperatorAvatar ? "auto" : "100%" }}>
                <FileUploadInput
                  label="Аватар оператора:"
                  placeholder="Загрузить"
                  onChange={fileChangeHandler}
                  description={`.jpeg, .jpg или .png до ${MAX_AVATAR_SIZE_MB} Мб`}
                />
              </div>
              {fallbackOperatorAvatar && (
                <Avatar
                  size="large"
                  url={CROP_IMG_URL + fallbackOperatorAvatar}
                />
              )}
            </AvatarOperatorFieldRoot>

            <ModalSeparator>
              <CheckBoxField
                type="slider"
                caption="Отправка файлов"
                captionPosition="after"
                checked={files?.enabled}
                onChange={handleCheckboxChange(
                  EReducerActions.FilesEnabled,
                  !files?.enabled
                )}
              />
            </ModalSeparator>
            {!!files?.enabled && (
              <FielsFieldsRoot>
                <FormField label="Допустимые расширения файлов" required>
                  {/* <Select
                    isMulti
                    options={props.fileExtensions}
                    selected={props.fileExtensions.filter((e) =>
                      files?.allowedTypes.includes(e.value)
                    )}
                    onSelectOption={(val) =>
                      dispatch({
                        type: EReducerActions.FilesAllowedTypes,
                        data: val.map((v) => v.value)
                      })
                    }
                  /> */}

                  <Autocomplete
                    isMulti
                    isSearchable
                    options={props.fileExtensions}
                    selected={props.fileExtensions.filter((e) =>
                      files?.allowedTypes.includes(e.value)
                    )}
                    onSelectOption={(val) =>
                      dispatch({
                        type: EReducerActions.FilesAllowedTypes,
                        data: val.map((v) => v.value)
                      })
                    }
                  />
                </FormField>
                <TwoColumnsRoot>
                  <Input
                    label="Количество файлов за раз"
                    required
                    placeholder="5"
                    value={files?.limit.toString()}
                    onChange={handleInputChange(EReducerActions.FilesLimit)}
                  />
                  <Input
                    label="Максимальный размер файла (MB)"
                    required
                    placeholder="5"
                    value={bytesToMegabytes(files?.size).toString()}
                    onChange={handleInputChange(EReducerActions.FilesSize)}
                  />
                </TwoColumnsRoot>
              </FielsFieldsRoot>
            )}
            <ModalSeparator>Выбор стилевого оформления</ModalSeparator>
            <TwoColumnsRoot>
              <StylesFieldsRoot>
                <FormField label="Форма чата" required>
                  <Select
                    options={chatShapeOptions}
                    selected={chatShapeOptions.filter(
                      (o) => o.value === appearance?.chatShape
                    )}
                    onSelectOption={([val]) =>
                      dispatch({
                        type: EReducerActions.AppearanceChatShape,
                        data: val.value
                      })
                    }
                  />
                </FormField>
              </StylesFieldsRoot>
              <StylesFieldsRoot>
                <FormField label="Форма полей и кнопок" required>
                  <Select
                    options={fieldAndBtnShapeOptions}
                    selected={fieldAndBtnShapeOptions.filter(
                      (o) => o.value === appearance?.controllerShape
                    )}
                    onSelectOption={([val]) =>
                      dispatch({
                        type: EReducerActions.AppearanceControllerShape,
                        data: val.value
                      })
                    }
                  />
                </FormField>
              </StylesFieldsRoot>
            </TwoColumnsRoot>

            <ThemeFieldsRoot>
              <FormField label="Цветовая тема" required>
                <Select
                  options={colorThemeOptions}
                  selected={colorThemeOptions.filter(
                    (o) => o.value === appearance?.theme
                  )}
                  onSelectOption={([val]) =>
                    dispatch({
                      type: EReducerActions.AppearanceTheme,
                      data: val.value
                    })
                  }
                />
              </FormField>
            </ThemeFieldsRoot>

            <ModalSeparator>
              <CheckBoxField
                type="slider"
                caption="Настроить цвета"
                captionPosition="after"
                checked={appearance?.colors?.enabled}
                onChange={handleCheckboxChange(
                  EReducerActions.AppearanceColorsEnabled,
                  !appearance?.colors?.enabled
                )}
              />
            </ModalSeparator>

            {!!appearance?.colors?.enabled && (
              <>
                <TwoColumnsRoot>
                  <FormField label="Баблы сообщений">
                    <ColorPicker
                      initialValue={appearance?.colors?.bubble}
                      onChange={(val) =>
                        dispatch({
                          type: EReducerActions.AppearanceColorsBubble,
                          data: val
                        })
                      }
                    />
                  </FormField>
                  <FormField label="Фон чата">
                    <ColorPicker
                      initialValue={appearance?.colors?.chat}
                      onChange={(val) =>
                        dispatch({
                          type: EReducerActions.AppearanceColorsChat,
                          data: val
                        })
                      }
                    />
                  </FormField>
                </TwoColumnsRoot>
                <TwoColumnsRoot>
                  <FormField label="Фон поля ввода">
                    <ColorPicker
                      initialValue={appearance?.colors?.messageInput}
                      onChange={(val) =>
                        dispatch({
                          type: EReducerActions.AppearanceColorsMessageInput,
                          data: val
                        })
                      }
                    />
                  </FormField>
                  <FormField label="Цвет шрифта">
                    <ColorPicker
                      initialValue={appearance?.colors?.font}
                      onChange={(val) =>
                        dispatch({
                          type: EReducerActions.AppearanceColorsFont,
                          data: val
                        })
                      }
                    />
                  </FormField>
                </TwoColumnsRoot>
                <TwoColumnsRoot>
                  <FormField label="Интерактивные элементы">
                    <ColorPicker
                      initialValue={appearance?.colors?.triggerButton}
                      onChange={(val) =>
                        dispatch({
                          type: EReducerActions.AppearanceColorsTriggerButton,
                          data: val
                        })
                      }
                    />
                  </FormField>
                  <FormField label="Шрифт системных сообщений">
                    <ColorPicker
                      initialValue={appearance?.colors?.font2}
                      onChange={(val) =>
                        dispatch({
                          type: EReducerActions.AppearanceColorsFont2,
                          data: val
                        })
                      }
                    />
                  </FormField>
                </TwoColumnsRoot>
              </>
            )}
          </>
        )}

        <ActionButtons>
          <Button
            text="Удалить"
            theme="red"
            type="default"
            onClick={props.onDelete}
          />
          <Button
            text="Сохранить"
            theme="green"
            type="default"
            onClick={handleSaveChanges}
          />
        </ActionButtons>
      </ModalRoot>
    </>
  );
};

export default connect(
  (state: StoreState.State) => ({
    fsFiles: state.FileStorage.files
  }),
  (dispatch) => ({
    pushFiles: (files) => dispatch(pushFiles(files)),
    cleanFileList: () => dispatch(cleanFileList()),
    sendFile: (uuid, onSuccess, onError) =>
      dispatch(sendFile(uuid, onSuccess, onError))
  })
)(ChannelSettingsModal);

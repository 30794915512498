import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../ReactCore/store/types";
import { TLocationData, TProps } from "./DashboardFilters";

import ThemeProvider from "../../Components/ThemeProvider";
import FilterPanel from "./Components/FilterPanel/FilterPanel";

import {
  toggleFilterPanel,
  changeFilterPanelData,
  dashboardFilterDictionariesLoading,
  setConfirmInProgress,
  resetDashboardFilterForm
} from "./Store/DashboardFilters.action";

import { INITIAL_FORM_DATA } from "./consts";

import * as filtersSelectors from "./Store/DashboardFilters.selectors";

import Informer from "../../arm/Wrappers/Informer.js";

class DashboardFiltersProvider extends PureComponent<TProps> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.resetDashboardFilterForm();

    const defaultDateStart = new Date(),
      defaultDateEnd = new Date();
    defaultDateStart.setHours(0, 0, 0);
    defaultDateEnd.setHours(23, 59, 59);

    let date_start = this.props.formData.date_start
      ? this.props.formData.date_start
      : defaultDateStart;
    let date_end = this.props.formData.date_end
      ? this.props.formData.date_end
      : defaultDateEnd;

    if (this.preSelectedDataChanged()) this.props.toggleFilterPanel();

    if (
      this.props.preSelectedData.date_start &&
      this.props.preSelectedData.date_end
    ) {
      const tommorow = defaultDateEnd.setDate(defaultDateEnd.getDate() + 1)
      date_start = this.props.preSelectedData.date_start;
      date_end =
        this.props.preSelectedData.date_end > tommorow
          ? tommorow
          : this.props.preSelectedData.date_end;
    }

    const preSelectedPeriodData = {
      ...this.props.preSelectedData,
      date_start: date_start,
      date_end: date_end,
      mrf: "any",
      rf: "any",
      region: "any",
      channel: "any"
    };

    this.props.onChange(preSelectedPeriodData);
    this.props.changeFilterPanelData(preSelectedPeriodData);
    this.changeHandler(preSelectedPeriodData);

    const preSelectedLocationData: TLocationData = {};

    if (this.props.preSelectedData.mrf) {
      preSelectedLocationData.mrf = this.props.preSelectedData.mrf;
    }
    if (this.props.preSelectedData.rf) {
      preSelectedLocationData.rf = this.props.preSelectedData.rf;
    }
    if (this.props.preSelectedData.region) {
      preSelectedLocationData.region = this.props.preSelectedData.region;
    }
    if (this.props.preSelectedData.channel) {
      preSelectedLocationData.channel = this.props.preSelectedData.channel;
    }

    this.props.dashboardFilterDictionariesLoading(preSelectedLocationData);
  }

  componentWillUnmount() {
    this.props.resetDashboardFilterForm();
  }

  preSelectedDataChanged() {
    let dataChanged = false;
    let { preSelectedData } = this.props;

    Object.keys(preSelectedData).map((key) => {
      if (key === "date_start" || key === "date_end") return;
      if (preSelectedData[key] !== INITIAL_FORM_DATA[key]) {
        dataChanged = true;
      }
    });

    return dataChanged;
  }

  onAlert(text: string) {
    if (!text || !text.length) {
      return;
    }
    new Informer(text, 3000).show();
  }

  changeHandler(data) {
    if (
      data.mrf !== this.props.formData.mrf &&
      data.mrf !== "any" &&
      data.mrf !== "none"
    ) {
      data.rf = "any";
      data.region = "any";
    }

    if (
      data.rf !== this.props.formData.rf &&
      data.rf !== "any" &&
      data.rf !== "none"
    ) {
      data.region = "any";
    }

    this.props.changeFilterPanelData(data);
  }

  confirmHandler() {
    this.props.setConfirmInProgress(true);
    setTimeout(() => {
      this.props.setConfirmInProgress(false);
    }, DASHBOARD_DELAY_SECONDS * 1000);
    const remark_phone = this.props.formData["remark_phone"].replace(
      /[^0-9]/gim,
      ""
    );
    this.props.onChange({ ...this.props.formData, remark_phone });
  }

  resetHandler() {
    this.props.setConfirmInProgress(false);
    const currentPeriodData = {
      date_start: this.props.formData.date_start,
      date_end: this.props.formData.date_end
    };

    const newData = {
      ...INITIAL_FORM_DATA,
      ...currentPeriodData
    };
    this.props.changeFilterPanelData(newData);

    this.props.onChange(newData);
  }

  getChangedFileds(realData, initialData) {
    if (realData) {
      let result = {};
      for (let key in realData) {
        if (
          key !== "date_start" &&
          key !== "date_end" &&
          realData[key] !== initialData[key]
        ) {
          result[key] = 1;
        }
      }
      return result;
    }
    return [];
  }

  render() {
    return (
      this.props.formData && (
        <ThemeProvider>
          <FilterPanel
            onChange={this.changeHandler.bind(this)}
            onConfirm={this.confirmHandler.bind(this)}
            onReset={this.resetHandler.bind(this)}
            onAppealChange={this.props.onAppealChange}
            recentAppeals={this.props.recentAppeals}
            options={{
              sl: this.props.slList,
              csi: this.props.csiList,
              whoProcessed: this.props.whoProcessedList,
              mrf: this.props.mrfList,
              rf: this.props.rfList,
              status: this.props.statusList,
              region: this.props.regionList,
              channel: this.props.channelList
            }}
            data={this.props.formData}
            onToggleFilterPanel={this.props.toggleFilterPanel}
            openedExpandedFields={this.props.openedFiltersPanel}
            changedFields={this.getChangedFileds.call(
              this,
              this.props.formData,
              INITIAL_FORM_DATA
            )}
            onAlert={this.onAlert.bind(this)}
            isTimeAvailable={IS_DASHBOARD_PERIOD_WITH_TIME === "1"}
            confirmInProgress={this.props.confirmInProgress}
          />
        </ThemeProvider>
      )
    );
  }
}

const mapState = (state: StoreState.State) => {
  const openedFiltersPanel = state.DashboardFilters.status.openedFiltersPanel;
  const initialDataLoaded = state.DashboardFilters.status.initialDataLoaded;
  const formData = state.DashboardFilters.formData;
  // select options
  const whoProcessedList = state.DashboardFilters.whoProcessedList;
  const slList = state.DashboardFilters.slList;
  const csiList = state.DashboardFilters.csiList;
  const channelList = state.DashboardFilters.channelList;
  const statusList = state.DashboardFilters.statusList;
  const mrfList = filtersSelectors.getMrfs(state);
  const rfList = filtersSelectors.getFilteredRfs(state);
  const regionList = filtersSelectors.getFilteredRegions(state);
  const confirmInProgress = filtersSelectors.getConfirmInProgress(state);
  const recentAppeals = filtersSelectors.recentAppeals(state);

  return {
    openedFiltersPanel,
    initialDataLoaded,
    formData,
    whoProcessedList,
    slList,
    csiList,
    channelList,
    mrfList,
    rfList,
    statusList,
    regionList,
    confirmInProgress,
    recentAppeals
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleFilterPanel: () => dispatch(toggleFilterPanel()),
  changeFilterPanelData: (data) => dispatch(changeFilterPanelData(data)),
  setConfirmInProgress: (data) => dispatch(setConfirmInProgress(data)),
  dashboardFilterDictionariesLoading: (data) =>
    dispatch(dashboardFilterDictionariesLoading(data)),
  resetDashboardFilterForm: () => dispatch(resetDashboardFilterForm())
});

export default connect(mapState, mapDispatchToProps)(DashboardFiltersProvider);

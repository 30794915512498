import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionAgents } from "../Store/Monitoring.reducer";
import Informer from "../../../arm/Wrappers/Informer";

function* sendTerminateSaga({
  type,
  payload
}: PayloadAction<{ userId: number; cb: Function }>) {
  yield put(actionAgents.startLoading(type));

  const { userId, cb } = payload;

  try {
    const res = yield httpRequest(
      "POST",
      `bff/dashboard/agents/${userId}/forced-logout`
    );

    if (res.success) {
      const informer = new Informer("Операция завершена успешно", 1500);
      informer.show();
    } else {
      const informer = new Informer("Не удалось выполнить операцию", 1500);
      informer.show();
    }
  } catch (error) {
    const informer = new Informer("Не удалось выполнить операцию", 1500);
    informer.show();
    console.error(error);
  } finally {
    yield cb();
    yield put(actionAgents.finishLoading(type));
  }
}

export default function*() {
  yield takeEvery(actionAgents.sendTerminate, sendTerminateSaga);
}

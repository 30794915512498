export default {
  notification: {
    status: undefined,

    count: 0,

    favicon: {
      default: "/www/img/favicon.ico",
      current: false,

      change: function(icon) {
        if (icon == this.current) return;

        if (icon == this.default) {
          core.notification.titleMarquee.destroy();
          document.title = core.nav.title;
        }

        core.draw.favicon(icon);

        this.current = icon;
      }
    },

    /**
     * Бегущая строка в title страницы
     */
    titleMarquee: {
      delay: 4000,
      speed: 200,
      timer: !1,
      length: 0,
      active: !1,
      block: !1,
      realText: "",
      tape: "",

      init: function() {
        if (this.active || this.block) return;

        this.active = !0;
        this.realText = document.title;
        this.tape = this.realText;
        this.length = this.realText.length;

        this.loop();
      },

      loop: function() {
        if (core.notification.titleMarquee.length > 0) {
          // прокрутка бегущей строки
          setTimeout(function() {
            if (
              !core.notification.titleMarquee.active ||
              core.notification.titleMarquee.block
            )
              return;

            var tape = core.notification.titleMarquee.tape;
            core.notification.titleMarquee.tape = tape.substr(1, tape.length);
            document.title = core.notification.titleMarquee.tape
              ? core.notification.titleMarquee.tape
              : core.nav.title;

            // считаем сдвиг букв
            core.notification.titleMarquee.length--;

            core.notification.titleMarquee.loop();
          }, core.notification.titleMarquee.speed);
        } else {
          core.notification.titleMarquee.block = !1;

          // задержка перед началом прокрутки
          setTimeout(function() {
            if (!core.notification.titleMarquee.active) return;

            document.title = core.notification.titleMarquee.realText;
            core.notification.titleMarquee.tape =
              core.notification.titleMarquee.realText;
            core.notification.titleMarquee.length =
              core.notification.titleMarquee.realText.length;
            core.notification.titleMarquee.block = !1;
            core.notification.titleMarquee.loop();
          }, core.notification.titleMarquee.delay);
        }
      },

      destroy: function() {
        this.active = !1;
        this.block = !1;
        if (this.realText) {
          document.title = this.realText;
          this.realText = "";
        }
      }
    },

    check: function() {
      if (window.Notification) {
        // HTML5 Notification поддерживается
        if (Notification.permission) {
          core.notification.status = Notification.permission;
          return true;
        }
      } else {
        // HTML5 Notification не поддерживается
        core.notification.status = false;
        return false;
      }
    },

    send: function(opt) {
      var default_opt = {
        title: "",
        text: "",
        tag: "",
        icon: "",
        renotify: true,
        change_favicon: false,
        sound: undefined,
        on: {
          show: undefined,
          click: undefined,
          close: undefined,
          error: undefined
        }
      };

      opt = $.extend(true, {}, default_opt, opt);

      if (!core.notification.check()) {
        if (opt.change_favicon) {
          core.notification.favicon.change(opt.change_favicon);
        }

        if (opt.sound !== undefined) {
          var sound = new Audio(opt.sound);
          sound.play();
        }

        return false;
      }

      core.notification.count++;

      if (opt.change_favicon) {
        core.notification.favicon.change(opt.change_favicon);
      }

      Notification.requestPermission();

      core.notification[
        "instance_" + core.notification.count
      ] = new Notification(opt.title, {
        body: $("<div>" + opt.text + "</div>").text(),
        tag: opt.tag,
        icon: opt.icon,
        renotify: true
      });

      core.notification[
        "instance_" + core.notification.count
      ].onshow = function() {
        if (opt.sound !== undefined) {
          var sound = new Audio(opt.sound);
          sound.play();
        }

        if (typeof opt.on.show === "function") opt.on.show();
      };
      core.notification["instance_" + core.notification.count].onclick =
        opt.on.click;
      core.notification["instance_" + core.notification.count].onclose =
        opt.on.close;
      core.notification["instance_" + core.notification.count].onerror =
        opt.on.error;
    }
  },

  debug: false,

  windowActive: true,

  log: function(input) {
    if (core.debug) console.log(input);
  },

  user: {
    onlineStatus: false,

    platform: function() {
      var pl = navigator.platform.toLowerCase();

      if (pl.match(/(macintel|macppc|mac68k|macintosh)/))
        core.user.platform = "macos";
      else if (
        pl.match(
          /(linux|linux\si686|linux\si686\sx11|linux\sarmv7l|linux\sx86_64\sx11|freebsd\si386|freebsd\samd64)/
        )
      )
        core.user.platform = "linux";
      else core.user.platform = "windows"; //Windows Win32 Win16 WinCE
    },

    tab_active: true,

    browser: function() {
      var ua = navigator.userAgent.toLowerCase(),
        match =
          /(chrome)[ \/]([\w.]+)/.exec(ua) || // chrome
          /(webkit)[ \/]([\w.]+)/.exec(ua) || // webkit
          /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) || // opera
          /(msie) ([\w.]+)/.exec(ua) || // msie
          /(edge) ([\w.]+)/.exec(ua) || // edge
          (ua.indexOf("compatible") < 0 &&
            /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua)) ||
          []; // mozilla

      core.user.browser = {
        name: match[1] || undefined,
        version: parseInt(match[2], 10) || undefined
      };
    },

    key: {
      i: 0,

      code: 0,

      name: function(key) {
        key = key || core.user.key.code;

        if (key) {
          var code_arr = {
            17: "ctrl",
            13: "enter",
            38: "up",
            40: "down",
            37: "left",
            39: "right"
          };

          if (core.user.platform == "macos" && key == 91) return "ctrl";
          else return code_arr[key];
        } else return false;
      }
    },

    winWidth: 0,

    winHeight: 0,

    scrollTop: 0,

    scrollBottom: 0,

    scrollDirection: undefined,

    scrollSize: function() {
      var scrollBox = $("<div>", {
        style: "overflow:scroll;position:absolute;width:50px;height:50px;",
        html: "<div>&nbsp;<br>&nbsp;</div>"
      });

      $("body").append(scrollBox);
      core.user.scrollSize = Math.max(
        0,
        scrollBox.width() - scrollBox.children("div:first-child").width()
      );
      scrollBox.remove();
    },

    mouseX: 0,

    mouseY: 0
  },

  tools: {
    rand: function(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },

    loadCache: {},

    loadCode: function(file, callback) {
      var type;

      if (typeof file == "object") {
        type = file.type;
        file = file.path;
      } else type = file.split(".").pop() || false;

      var external = file.match(/^(https?|s?ftp)(.+)/i),
        path =
          external !== null && external[0] !== undefined
            ? file
            : "/www/" + type + "/" + file + "?v=" + core.version,
        tag,
        params;

      if (!type) {
        //console.log('Unknown file type ' + type);
        return false;
      }

      if (core.tools.loadCache[path] !== "success") {
        if (type === "js") {
          $.ajax({
            url: path,
            cache: true,
            async: false,
            type: "GET",
            dataType: "script",
            success: function() {
              core.tools.loadCache[path] = "success";
              if (typeof callback === "function") callback();
            }
          });
        } else if (type === "css") {
          var el = document.createElement("link");
          el.rel = "stylesheet";
          el.href = path;

          if (el.readyState) {
            // IE
            el.onreadystatechange = function() {
              if (el.readyState == "loaded" || el.readyState == "complete") {
                el.onreadystatechange = null;

                core.tools.loadCache[path] = "success";
                if (typeof callback === "function") callback();
              }
            };
          } else {
            // Others
            el.onload = function() {
              core.tools.loadCache[path] = "success";
              if (typeof callback === "function") callback();
            };
          }

          document.getElementsByTagName("head")[0].appendChild(el);
        }
      } else {
        if (typeof callback === "function") callback();
      }
    },

    declOfNum: function(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },

    eventsCheck: function(el, event) {
      if (event === undefined) {
        var ev = $._data(el[0], "events");
        return ev || false;
      } else return $._data(el, "events")[event] === undefined ? false : true;
    },

    browserCheck: function(minimal, redirect) {
      redirect = redirect || false;

      var support =
        minimal[core.user.browser.name] === undefined ||
        minimal[core.user.browser.name] === undefined ||
        minimal[core.user.browser.name] <= core.user.browser.version
          ? true
          : false;

      if (!support) {
        if (redirect) {
          document.location.href = core.nav.getUrl({
            sol: "page",
            sec: "badbrowser",
            params: minimal
          });
        }
        return false;
      } else return true;
    },

    copyArr: function(from) {
      return $.extend(true, [], from);
    },

    scrollTo: function(e, params) {
      var el = $("body,html"),
        length;

      if (typeof e === "object") {
        length = e.offset().top;
      } else length = e;

      if (Math.abs(length - core.user.scrollTop) > core.user.height) {
        if (length > core.user.scrollTop)
          el.scrollTop(length - core.user.height);
        else el.scrollTop(length + core.user.height);
      }
      el.animate({ scrollTop: length + "px" }, params);
    },

    cancelBubble: function(e) {
      if (!e) window.event.cancelBubble = true;
      else if (e.stopPropagation) e.stopPropagation();
    },

    isNumeric: function(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },

    /**
     * Для отчетов конвертит даты типа "22.03.2005" в timestamps UTC
     * @param {string} date_str - строка с датой типа "Date.Month.Year"
     * @param {array} time - массив со временем [H, i, s]
     * @return {int} - timestamp UTC в секундах!
     */
    convertDatesToUTC: function(date_str, time) {
      if (!date_str || !time.length) {
        return;
      }

      var date_parts = date_str.split(".").map(function(part, index) {
        var date_part = +part;
        if (index === 1) {
          date_part--;
        }
        return date_part;
      });

      return (
        new Date(
          date_parts[2],
          date_parts[1],
          date_parts[0],
          time[0],
          time[1],
          time[2]
        ).getTime() / 1000
      );
    }
  },

  form: {
    itemFocus: function(el) {
      var wrap = el.parent();
      wrap.removeClass("selected");
      wrap.addClass("focus");
      if (wrap.hasClass("fail")) core.form.tooltipShow(wrap.children(".error"));
    },

    itemBlur: function(el) {
      var wrap = el.parent();
      if (el.val() != el.find("option:first").prop("value"))
        wrap.addClass("selected");

      wrap.removeClass("focus");
      if (el.val().length > 0) wrap.addClass("filled");
      else if (wrap.hasClass("filled")) wrap.removeClass("filled");

      if (wrap.hasClass("fail")) {
        core.tooltip.hide(wrap.children(".error"));
        wrap.removeClass("fail");
      }
    },

    rangeChange: function(el) {
      var val = el.val();
      el.prev() /* .css('left', (val/el.attr('max')*100)+'%') */
        .html(el.val() + " из " + el.attr("max"));
    },

    selectChange: function(el) {
      if (!el.val().length) el.parent().removeClass("filled");
      else el.parent().addClass("filled");
    },

    check: function(form, prefix, array) {
      if (typeof array === "undefined") return false;

      var count = 0;
      $.each(array, function(name, text) {
        var fields = form.find("[name='" + name + "']").closest(".wsForm_field")
          .length
          ? form.find("[name='" + name + "']").closest(".wsForm_field")
          : form.find("[name='" + name + "[]']").closest(".wsForm_field");

        if (text.length) {
          count++;
          if (fields.length > 1) {
            fields.each(function(key, field) {
              var val = $(field)
                .find("[name='" + name + "[]']")
                .val();
              if (val != false) {
                $(field).removeClass("fail");
              } else {
                $(field)
                  .addClass("fail")
                  .find(".wsForm_fieldError")
                  .html(text);
              }
            });
          } else {
            if (fields.hasClass("fail")) {
              fields.find(".wsForm_fieldError").html(text);
            } else {
              fields
                .addClass("fail")
                .find(".wsForm_fieldError")
                .html(text);
            }
          }
        } else {
          fields.removeClass("fail");
        }
      });
      return !count ? true : false;
    },

    fileOver: function(el) {
      var wrap = el.parent();

      core.tooltip.show(wrap, {
        body: "Загрузить файл",
        align: "left_middle",
        position: {
          top: -1,
          left: 3
        }
      });
    },

    fileChange: function(el) {
      var name = el.val().split("\\"),
        wrap = el.parent();

      name = name[name.length - 1];

      if (!name) {
        wrap.removeClass("filled");
        name = "Загрузить";
      } else {
        wrap.addClass("filled");
      }

      wrap.find("span").text(name);
    },

    tooltipShow: function(el) {
      if (core.tooltip.el === undefined) {
        core.tooltip.show(el, {
          body: el.children("span").html(),
          align: "left_middle",
          position: {
            top: 0,
            left: -7
          }
        });
      }
    },

    submit: function(form, callback) {
      core.nav.send({
        url: form.attr("action"),
        type: form.attr("method") || "POST",
        data: form.serializeArray(),
        after: typeof callback == "function" ? callback : undefined
      });
    },

    /**
     * Отображает в UI установленное в input type="range" значение;
     * @param input - jQuery объект элемента формы input type="range";
     * атрибуты data-label-* задают склонения существительных к цифрами.
     * например, 1 обращениЕ, 2 обращениЯ, 55 обращениЙ;
     * если это не важно, то используйте в форме просто data-label с единой единицей
     * измерения типа % или шт.
     */
    inputRange: function(input) {
      var score = input.closest(".wsForm_field").find(".wsForm_range_score"),
        label_default = score.data("label"),
        label_0 = score.data("label-0") || label_default,
        label_1 = score.data("label-1") || label_default,
        label_2_4 = score.data("label-2-4") || label_default,
        value = input.val(),
        label = label_default,
        last_digit = +value.slice(-1),
        result = value + label;

      if (!core.tools.isNumeric(value)) {
        console.error("Значения ползунка должны быть цифрами!");
        return;
      }

      if (+value === 0 && score.data("label-0")) result = label_0;
      if (last_digit === 1) result = value + label_1;
      if (last_digit >= 2 && last_digit <= 4) result = value + label_2_4;
      if (value > 10 && value < 15) result = value + label_default;

      score.text(result);
    }
  },

  informer: {
    el: undefined,

    resize: function() {
      core.informer.el.css({
        top: core.user.winHeight / 2 - core.informer.el.outerHeight() / 2,
        left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2 + 100
      });
    },

    show: function(text, timeout) {
      if (core.informer.el === undefined) {
        core.informer.el = $("<div />", {
          id: "ws_informer",
          html: text
        });

        $("#page").append(core.informer.el);
        $(window).on({
          resize: core.informer.resize
        });

        core.informer.resize();
        core.informer.el.addClass("show");
      } else {
        core.informer.el.html(text);
        core.informer.resize();
        clearTimeout(core.informer.timer);
        core.informer.el.stop(true).css({
          display: "block",
          opacity: 1
        });
      }

      core.informer.timer = setTimeout(core.informer.hide, timeout || 86400);
    },

    hide: function() {
      if (core.informer.el === undefined) return false;

      $(window).off({
        resize: core.informer.resize
      });

      core.informer.el.animate(
        { opacity: 0 },
        {
          duration: 500,
          complete: function() {
            core.informer.el.remove();
            core.informer.el = undefined;
          }
        }
      );
    }
  },

  /**
   * Календарь
   */
  calendar: {
    /**
     * Объект календаря - сам попап
     */
    el: undefined,

    position: "bottom",

    target: undefined,

    /**
     * Контейнер добавления, при скроле которого будет проскролливатсья и сам календарь
     */
    scrollContainer: undefined,

    /**
     * Инициализация скриптов, стилей и самого календаря
     * @param {Node} el элемент вставки основного содержимого
     * @param {Object} opt опции календаря
     */
    init: function(el, opt) {
      if (el === undefined) {
        return false;
      }
      core.tools.loadCode("calendar.css", function() {
        core.tools.loadCode("calendar.js", function() {
          var default_opt = {
            flat: true,
            mode: "single",
            format: "d.m.Y"
          };
          opt = $.extend(true, {}, default_opt, opt);
          el.pickmeup(opt);
        });
      });
    },

    clearValue: function(e, callback) {
      core.tools.cancelBubble(e);
      var $input = $(e.target)
          .closest("div")
          .find("input"),
        value = $input.val();

      if ($input && value) {
        $input.val("");
        if (callback) {
          callback();
        }
        $input.change();
      }
    },

    /**
     * Дестрой календаря
     * @param {Node} el
     */
    destroy: function(el) {
      el.pickmeup("destroy");
    },

    blurField: function(el) {
      if (el[0] && el.find("input:focus")[0]) {
        el.find("input:focus").blur();
      }
    },

    /**
     * Показать календарь
     * @param {Node} el активный элемент (onclick) открытия календаря
     * @param {Event} event объект события
     * @param {Node} scrollContainer контейнер скролла, куда будет помещен контейнер календаря.
     *                               Сюда передавать тот, на котором будет происходить скролл
     */
    show: function(el, event, scrollContainer, position) {
      var me = core.calendar;
      if (me.el !== undefined) {
        me.hide();
      }
      me.target = el;
      me.position = position || "bottom";
      me.scrollContainer = scrollContainer || $("body");

      var styles = $.extend(
        {},
        { zIndex: 199 },
        me._getPosition(me.target, me.position)
      );

      me.el = $("<div />", {
        class: "calendar_wrap",
        html: '<div class="calendar"></div>',
        css: styles
      });

      if (me.position === "top") {
        me.el.addClass("top");
      }

      me.scrollContainer.append(me.el);

      me.init(me.el.children("div"), {
        date: el.children("input").val(),
        change: function() {
          var input = el.children("input");
          input.val($(this).pickmeup("get_date", "d.m.Y")).trigger("change");
          input.trigger("blur");
          me.hide();
        }
      });
      $(window).on("resize.calendar", function() {
        me.hide();
      });
      $(window).on("scroll.calendar", function() {
        me.hide();
      });
      me.scrollContainer.on("scroll.calendar", function() {
        me.hide();
      });
      core.tools.cancelBubble(event);
    },

    _getPosition: function($target, position) {
      position = position || "bottom";

      var OFFSET = 15;

      var height = $($target.get(0)).innerHeight(),
        offset = $($target.get(0)).offset(),
        computedPosition = {};

      switch (position) {
        case "top":
          computedPosition.top = offset.top - 221 - OFFSET;
          break;
        case "bottom":
          computedPosition.top = offset.top + height + OFFSET;
          break;
        default:
          computedPosition.top = "0px";
      }

      computedPosition.left = offset.left - window.pageXOffset;

      return computedPosition;
    },

    /**
     * Закрыть календарь
     */
    hide: function() {
      var me = core.calendar;
      if (me.el) {
        me.destroy(me.el.children("div"));
        me.el.remove();
        me.el = undefined;
        me.target = undefined;
        me.scrollContainer.off("scroll.calendar");
        me.scrollContainer = undefined;
        $(window).off("resize.calendar");
        $(window).off("scroll.calendar");
      }
    }
  },

  tooltip: {
    show: function(el, opt, move) {
      if (
        (core.tooltip.el !== undefined && core.tooltip.el.is(":visible")) ||
        core.tooltip.timer !== undefined
      )
        return false;

      var default_opt = {
        children: undefined,
        align: "top_left",
        css: undefined,
        fixed: false,
        body: "",
        delay: 0,
        position: {
          top: 0,
          left: 0
        }
      };

      core.tooltip.el = $("<div />", {
        id: "tooltip",
        html:
          '<span class="tooltipArr"></span><span class="tooltipBody"></span>'
      });

      opt = $.extend(true, {}, default_opt, opt);
      core.tooltip.timer = setTimeout(function() {
        var align,
          on,
          on_width,
          on_height,
          on_offset,
          t_width,
          t_height,
          t_left,
          t_top;

        align = opt.align.split("_");
        on = opt.children === undefined ? el : opt.children;
        on_offset = on.offset();
        on_width = on.outerWidth(true);
        on_height = on.outerHeight(true);

        core.tooltip.el.children(".tooltipBody").html(opt.body);
        core.tooltip.el.css({
          display: "block",
          left: -9999
        });
        if (opt.css !== undefined) core.tooltip.el.addClass(opt.css);

        $("#page").append(core.tooltip.el);

        t_width = core.tooltip.el.outerWidth();
        t_height = core.tooltip.el.outerHeight();

        for (var i = 0; i < 2; i++) {
          if (align[0] == "top") {
            t_top = on_offset.top + opt.position.top - t_height;
            if (core.user.scrollTop - t_top > 0) align[0] = "bottom";
            else break;
          } else if (align[0] == "bottom") {
            t_top = on_offset.top - opt.position.top + on_height;
            if (core.user.scrollBottom - t_height - t_top < 0) align[0] = "top";
            else break;
          } else if (align[1] == "top")
            t_top = on_offset.top + opt.position.top;
          else if (align[1] == "middle")
            t_top =
              on_offset.top - t_height / 2 + on_height / 2 + opt.position.top;
          else if (align[1] == "bottom")
            t_top = on_offset.top + on_height - t_height + opt.position.top;
        }

        for (i = 0; i < 2; i++) {
          if (align[0] == "left") {
            t_left = on_offset.left - t_width + opt.position.left;
            if (t_left < 0) align[0] = "right";
            else break;
          } else if (align[0] == "right") {
            t_left = on_offset.left + on_width + opt.position.left;
            if (t_left > core.user.winWidth) align[0] = "left";
            else break;
          } else if (align[1] == "left")
            t_left = on_offset.left + opt.position.left;
          else if (align[1] == "center")
            t_left = on_offset.left + opt.position.left - t_width / 2;
          else if (align[1] == "right")
            t_left = on_offset.left - t_width + on_width + opt.position.left;
        }

        opt.align = align[0] + "_" + align[1];

        core.tooltip.el
          .css({
            top: t_top,
            left: t_left
          })
          .addClass(opt.align);
      }, opt.delay);
    },

    hide: function(el, event) {
      if (typeof core.tooltip.timer !== "undefined") {
        core.tooltip.el.removeAttr("class").css("display", "none");

        clearTimeout(core.tooltip.timer);
        core.tooltip.el.remove();
        delete core.tooltip.el;
        delete core.tooltip.timer;
      }
    }
  },

  dropdown: {
    init: false,

    show: function(el, cl) {
      if (el.hasClass(cl)) {
        $(window).click();
        return false;
      } else el.addClass(cl);

      var hide = function(event) {
        if (core.dropdown.init === true) {
          core.dropdown.init = false;
          el.removeClass(cl);
          $(this).off("click", hide);
          core.tools.cancelBubble(event);
          return false;
        } else core.dropdown.init = true;
      };

      $(window).on("click", hide);
    }
  },

  draw: {
    cache: {},

    insert: function(tpls) {
      for (var tpl in tpls) {
        const node = document.querySelector(`.${tpl}`);
        if (node) {
          $(node).html(tpls[tpl]);
        }
      }
    },

    getTpls: function(data, system) {
      system = system || false;

      var tpls = {},
        url = core.nav.getUrl(data.url, "object"); // Приводим URL к структурированному объекту;

      if (core.nav.global !== undefined && core.nav.global !== url.sol)
        tpls.global =
          (!system
            ? window[core.nav.global].draw.tpls
            : window[core.nav.global].draw.tpls_system) || {};

      tpls.sol = !system
        ? window[url.sol].draw.tpls
        : window[url.sol].draw.tpls_system || {};

      if (window[url.sol][url.sec] !== undefined)
        tpls.sec =
          (!system
            ? window[url.sol][url.sec].draw.tpls
            : window[url.sol][url.sec].draw.tpls_system) || {};

      tpls = $.extend(
        true,
        {},
        typeof tpls.global === "function" ? tpls.global(data) : tpls.global,
        typeof tpls.sol === "function" ? tpls.sol(data) : tpls.sol,
        typeof tpls.sec === "function" ? tpls.sec(data) : tpls.sec
      );

      return tpls;
    },

    favicon: function(url) {
      var head = $("head"),
        link;

      head.children('link[rel*="icon"]').remove();

      link = $("<link>", {
        rel: "icon",
        type: "image/gif",
        href: url
      });

      head.append(link);

      link = $("<link>", {
        rel: "shortcut icon",
        type: "image/gif",
        href: url
      });

      head.append(link);
    },

    window_ids: [],

    window: function(opt) {
      core.tools.loadCode("window.js", function() {
        var default_opt = {
          id: false,
          body: undefined /* '<div' + (typeof opt.id!=='undefined' ? ' id="' + opt.id + '"' : '') + '><div class="modal-loading"><div class="circle"></div></div></div>' */,
          opacity: 0.6,
          hover: false,
          backgroundColor: "#000",
          valign: "middle", //top
          open: {
            type: "none", // none / fade
            speed: 0
          },
          close: {
            overlay: true,
            esc: true,
            type: "none", // none / fade
            speed: 0
          },
          callback: {
            beforeOpen: undefined,
            afterOpen: undefined,
            beforeClose: undefined,
            afterClose: undefined
          }
        };

        opt = $.extend(true, {}, default_opt, opt);

        if (opt.body === undefined) {
          if (opt.title !== undefined && opt.text !== undefined) {
            opt.body =
              '<div id="' +
              opt.id +
              '" class="wsModal"><div class="wsModal_title">' +
              opt.title +
              '</div><div class="wsModal_body">' +
              opt.text +
              "</div></div>";
          } else return false;
        }

        $(opt.body).modal({
          id: opt.id,
          overlay: {
            css: {
              opacity:
                !core.draw.window_ids.length || opt.hover ? opt.opacity : 0,
              backgroundColor: opt.backgroundColor
            }
          },
          closeOnOverlayClick: opt.close.overlay,
          closeOnEsc: opt.close.esc,
          openEffect: {
            type: opt.open.type,
            speed: opt.open.speed
          },
          closeEffect: {
            type: opt.close.type,
            speed: opt.close.speed
          },
          beforeOpen: function(data, el) {
            if (opt.id) {
              if (!opt.hover && opt.id) {
                $(
                  "#modal_" +
                    core.draw.window_ids[core.draw.window_ids.length - 1]
                ).hide();

                core.draw.window_ids.push(opt.id);
              }

              if (opt.close.overlay && opt.close.esc) {
                var modal = $("#modal_" + opt.id).find(".wsModal");

                if (modal != undefined)
                  modal.prepend(
                    '<span id="modalClose" class="wsModal_close" onclick="$(this).parent().modal(\'close\');"></span>'
                  );
              }
            }

            data.body.css("vertical-align", opt.valign);

            if (
              opt.callback.beforeOpen !== undefined &&
              typeof opt.callback.beforeOpen === "function"
            )
              opt.callback.beforeOpen(data);
          },
          afterOpen: function(data, el) {
            if (
              opt.callback.afterOpen !== undefined &&
              typeof opt.callback.afterOpen === "function"
            )
              opt.callback.afterOpen(data);
          },
          beforeClose: function(data, el) {
            if (
              opt.callback.beforeClose !== undefined &&
              typeof opt.callback.beforeClose === "function"
            )
              opt.callback.beforeClose(data);
          },
          afterClose: function(data, el) {
            if (!opt.hover && opt.id) {
              core.draw.window_ids.splice(core.draw.window_ids.length - 1, 1);
              $(
                "#modal_" +
                  core.draw.window_ids[core.draw.window_ids.length - 1]
              ).show();
            }

            if (
              opt.callback.afterClose !== undefined &&
              typeof opt.callback.afterClose === "function"
            )
              opt.callback.afterClose(data);
          }
        });
      });
    },

    shake: function(el, force) {
      force = typeof force == "number" ? force : 3;
      el.stop(true, true)
        .css({ position: "relative", left: 0 })
        .animate({ left: -(force * 3) }, 60)
        .animate({ left: force * 4 }, 120)
        .animate({ left: -(force * 2) }, 126)
        .animate({ left: force * 2 }, 84)
        .animate({ left: -force }, 126)
        .animate({ left: force }, 84)
        .animate({ left: 0 }, 42);
    }
  },

  nav: {
    url: undefined,

    global: "workspace",

    getUrl: function(url, type, fromLocation = false) {
      if (!fromLocation) {
        url = url || core.nav.url || location.pathname + location.search;
      } else {
        url = location.pathname + location.search;
      }
      type = type || (typeof url === "string" ? "object" : "string");

      if (typeof url === "string" && type === "object") {
        if (url.length) {
          var url_match =
            url.match(
              /^(https?|s?ftp)?:?\/?\/?(.+)?\/(.+)[?]sec=([^&]+)&(.+)/i
            ) ||
            url.match(/^(https?|s?ftp)?:?\/?\/?(.+)?\/(.+)[?]sec=([^&]+)&?$/i);

          if (
            url_match !== null &&
            (url_match[2] === undefined || url_match[2] == location.hostname)
          ) {
            url = {
              sol: url_match[3],
              sec: url_match[4],
              params: {}
            };

            if (url_match[5] !== undefined) {
              url_match = url_match[5].replace(/%3D/i, "=").split("&");

              if (url_match.length > 0) {
                var param;

                for (var i = 0; i < url_match.length; i++) {
                  param = url_match[i].split("=");

                  if (param[0].indexOf("[]") !== -1) {
                    if (typeof url.params[param[0]] === "undefined")
                      url.params[param[0]] = [];
                    url.params[param[0]].push(param[1]);
                  } else url.params[param[0]] = param[1];
                }
              }
            }
          }
        }
      } else if (
        typeof url === "object" &&
        type === "string" &&
        url.sol !== undefined &&
        url.sec !== undefined
      ) {
        url = decodeURI(
          window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "") +
            "/" +
            url.sol +
            "?sec=" +
            url.sec +
            (url.params !== undefined ? "&" + $.param(url.params) : "")
        );

        //url = decodeURIComponent(window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port: '') + string);
      }

      if (typeof url === "string" && url[0] == "/") {
        url = window.location.protocol + "//" + window.location.hostname + url;
      }

      return url;
    },

    file: {
      load: function(name, prepare) {
        core.tools.loadCode(name);
        if (prepare) core.nav.file.prepare(name);
      },

      prepare: function(fname) {
        var default_data = {
          websocket: {},
          draw: {
            cache: {},
            tpls: {},
            tpls_system: {}
          },
          nav: {
            browsers: {},
            history: true,
            init: undefined,
            destroy: undefined,
            before: undefined,
            after: undefined
          }
        };

        var name = fname.split(".")[0].split("_");

        if (name.length == 1) {
          core.draw.cache[name[0]] = {}; // Создаем объект для кэша элементов глобального контроллера
          window[name[0]] = $.extend(true, {}, default_data, window[name[0]]);
        } else
          window[name[0]][name[1]] = $.extend(
            true,
            {},
            default_data,
            window[name[0]][name[1]]
          );
      }
    },

    onClick: function(mode) {
      mode = mode || "on";

      if (mode == "on") $("body").on("click", "a", core.nav.onClick_func);
      else $("body").off("click", "a", core.nav.onClick_func);
    },

    onClick_func: function(event) {
      // Отлавливаем элемент, по которому произошел клик

      var el = $(this),
        href = el.attr("href") || "",
        url = core.nav.getUrl(href, "object"),
        onclick = el.attr("onclick");

      // Есть ли остановка события в событии onclick
      if (
        onclick === undefined ||
        el.attr("onclick").indexOf("return false") === -1
      ) {
        if (!href.match(/^(mailto|tel|webcal):/i)) {
          // Сверяем есть ли причины для открытия в новом окне
          if (
            core.user.key.name() != "ctrl" &&
            el.attr("target") != "_blank" &&
            typeof url === "object"
          ) {
            core.nav.do({
              el: el,
              url: url
            });
          } else window.open(href, "_blank");
        } else window.open(href, "_self");
      }

      if (event !== undefined) core.tools.cancelBubble(event);

      $(window).trigger("click");
      return false;
    },

    history: {
      set: function(url, title, replace) {
        url = core.nav.getUrl(url, "object");

        var url_uri = core.nav.getUrl(url, "string");
        var state = history.state;
        var state_uri;

        if (state !== null) state_uri = core.nav.getUrl(state.url, "string");

        if (replace === undefined) {
          if (state === null || url_uri == state_uri) replace = true;
          else replace = false;
        }

        // Записываем данные о положении скроллбара пользователя на странице
        if (!replace) {
          state.scroll = core.user.scrollTop;
          history.replaceState(state, null, state_uri);
        }

        var data = { url: url };
        if (title !== undefined) data.title = title;

        if (replace) history.replaceState(data, null, url_uri);
        else history.pushState(data, null, url_uri);
      },

      go: function() {
        core.nav.do({
          url: history.state.url,
          history: false,
          history_init: true
        });
      }
    },

    callback: function(name, data, core_start) {
      // Вызов функций для событий при AJAX переходах
      var onchange = name == "init" || name == "destroy" ? true : false; // Необходима проверка на смену URL контролера и экшена

      data.callback_name = name;

      // Функция для глобального контроллера
      if (
        (!onchange &&
          core.nav.global !== undefined &&
          core.nav.global !== data.url.sol) ||
        (onchange && core_start)
      )
        if (typeof window[core.nav.global].nav[name] === "function")
          window[core.nav.global].nav[name](data);

      // Функция для URL-контроллера
      if (
        window[data.url.sol] !== undefined &&
        (!onchange ||
          (onchange && (core_start || data.url.sol !== data.urlCur.sol)))
      )
        if (typeof window[data.url.sol].nav[name] === "function") {
          window[data.url.sol].nav[name](data);
        }

      // Функция для URL-экшен
      if (
        window[data.url.sol][data.url.sec] !== undefined &&
        (!onchange ||
          (onchange && (core_start || data.url.sec !== data.urlCur.sec)))
      ) {
        if (
          name !== "before" ||
          (name == "before" && data.url.sec == data.urlCur.sec)
        ) {
          const module = name !== "destroy" ? data.url.sol : data.urlCur.sol;
          const func = name !== "destroy" ? data.url.sec : data.urlCur.sec;

          if (typeof window[module][func].nav[name] === "function") {
            window[module][func].nav[name](data);
          }
        }
      }
    },

    init: function() {
      var data = {};

      data.urlCur = core.nav.url;
      data.url = core.nav.getUrl(data.url, "object");

      core.nav.url = data.url;

      // Устанавливаем стандартное значение для опции History.API
      data.history =
        data.history === undefined
          ? window[data.url.sol].nav.history || true
          : data.history;

      // Добавляем событие к ссылкам для переходов на AJAX (a.onclick)
      core.nav.onClick();

      // Включаем History.API
      if (!core.tools.eventsCheck(window, "popstate"))
        $(window).on("popstate", core.nav.history.go);

      // Выполнение псевдособытия init
      core.nav.callback("init", data, true);

      // Запись в History.API
      if (data.history) core.nav.history.set(core.nav.url);
    },

    send: function(opt) {
      opt.url = core.nav.getUrl(opt.url, "object");
      opt.history = opt.history === undefined ? true : opt.history;

      var tpls = {
        tpls: opt.tpls || {},
        tpls_system: opt.tpls_system || {}
      };

      var data = $.param(opt.data || {}) + "&" + $.param(tpls);

      if (opt.url !== undefined || opt.url.length) {
        if (typeof opt.before === "function") opt.before();

        $.ajax({
          url: core.nav.getUrl(opt.url, "string"),
          type: opt.type || "GET",
          cache: false,
          dataType: opt.dataType || "JSON",
          data: data,
          success: function(response) {
            if (typeof opt.after === "function") opt.after(response);

            // Запись в history.api
            if (opt.history) core.nav.history.set(core.nav.url);
          }
        });
      }
    },

    do: function(data) {
      data = data || {};

      data.history_init = data.history_init || false;

      data.urlCur = core.nav.url;
      data.url = core.nav.getUrl(data.url, "object");

      core.nav.url = data.url;

      // Устанавливаем стандартное значение для опции History.API
      data.history =
        data.history === undefined
          ? window[data.url.sol].nav.history || true
          : data.history;

      data.tpls = core.draw.getTpls(data) || {};
      data.tpls_system = core.draw.getTpls(data, true) || {};

      if (
        Object.keys(data.tpls).length ||
        Object.keys(data.tpls_system).length
      ) {
        // Выполнение запроса на сервер
        core.nav.send({
          url: data.url,
          history: data.history,
          tpls: data.tpls,
          tpls_system: data.tpls_system,
          before: function() {
            // Выполнение псевдособытия before
            core.nav.callback("before", data);
          },
          after: function(response) {
            // Записываем ответ сервера
            data.data = response.data;

            // Выполнение псевдособытия destroy
            core.nav.callback("destroy", data);

            // Выполнение псевдособытия insert
            core.draw.insert(response.tpls, data.url);

            // Выполнение псевдособытия init
            core.nav.callback("init", data);

            // Выполнение псевдособытия after
            core.nav.callback("after", data);

            // коллбек после обновления шаблона
            if (data.cb) data.cb();
          }
        });
      } else {
        // Выполнение псевдособытия before
        core.nav.callback("before", data);
        // Выполнение псевдособытия after
        core.nav.callback("after", data);
      }
    }
  },

  websocket: {
    online: true,

    tab_id: undefined,

    url: undefined,

    sync: {
      mode: "multi", // single

      handler: function() {}
    },

    connect: function() {
      core.websocket.block =
        core.websocket.url === undefined || core.nav.url.sec !== "stats"
          ? true
          : false;

      if (core.websocket.block) return false;

      console.log("Websocket: Connecting to " + core.websocket.url);
      core.websocket.listen = new WebSocket(core.websocket.url);
      core.websocket.listen.onopen = core.websocket.onopen;
      core.websocket.listen.onclose = core.websocket.onclose;
    },

    disconnect: function() {
      core.websocket.block = true;
      core.websocket.listen.close();
      console.log("Websocket: Disconnecting...");
    },

    check: function() {
      if (core.websocket.listen.readyState !== 1) {
        var time = core.tools.rand(5, 10);

        console.log("Websocket: Wait " + time + " seconds...");
        setTimeout(function() {
          core.websocket.connect();
        }, time * 1000);
      }
    },

    send: function(msg) {
      if (core.websocket.block) return false;

      msg = msg || {};
      msg.project_id = msg.project_id || core.nav.url.params.project_id || 0;
      msg.tab_id = core.websocket.tab_id;
      msg.user_id = core.user.id;
      msg.user_hash = core.user.hash;

      core.websocket.listen.send(JSON.stringify(msg));
    },

    onopen: function(msg) {
      console.log("Websocket: Connected");

      core.websocket.online = true;
      core.websocket.tab_id = core.websocket.tab_id || Math.random();
      core.storage.set("tab_id", core.websocket.tab_id);
      core.websocket.listen.onmessage = core.websocket.onmessage;
      core.websocket.listen.onerror = core.websocket.onerror;

      core.websocket.send({ callback: "connect" });
      core.informer.hide();
    },

    onmessage: function(msg) {
      /*
       * MES-1764
       */
      var guid = JSON.parse(msg.data).guid;
      if (
        guid &&
        (core.nav.url.sec !== "chat" ||
          (!core.websocket.block && core.nav.url.sec === "chat"))
      )
        core.websocket.listen.send(
          JSON.stringify({ callback: "message_read", guid: guid })
        );

      if (core.websocket.block) return false;

      msg = JSON.parse(msg.data);

      if (
        window[msg.module].websocket[msg.name] !== undefined &&
        window[msg.module].websocket[msg.name].onmessage !== undefined
      ) {
        window[msg.module].websocket[msg.name].onmessage(msg.data);
      }
    },

    onerror: function(error) {
      console.log(error);
    },

    onclose: function() {
      console.log("Websocket: Connection is closed");

      // Добавить callback для workspace и открытого контроллера
      // window['msg'].websocket.messenger.onclose();

      if (core.websocket.online) {
        if (window[core.nav.url.sol].websocket != undefined)
          if (typeof window[core.nav.url.sol].websocket.onclose == "function")
            window[core.nav.url.sol].websocket.onclose();

        if (window[core.nav.url.sol][core.nav.url.sec] != undefined)
          if (window[core.nav.url.sol][core.nav.url.sec].websocket != undefined)
            if (
              typeof window[core.nav.url.sol][core.nav.url.sec].websocket
                .onclose == "function"
            )
              window[core.nav.url.sol][core.nav.url.sec].websocket.onclose();
      }

      if (!core.websocket.block) {
        core.websocket.online = false;
        core.websocket.check();
      }
    }
  },

  storage: {
    set: function(name, value) {
      var type = typeof value;
      if (type === "object") value = JSON.stringify(value);
      localStorage[name] = value;
      localStorage[name + "_type"] = type;
      $(document).trigger("core.storage." + name);
    },

    get: function(name) {
      return localStorage[name + "_type"] && localStorage[name]
        ? /^(object|number|boolean)$/.test(localStorage[name + "_type"])
          ? JSON.parse(localStorage[name])
          : localStorage[name]
        : false;
    },

    remove: function(name) {
      delete localStorage[name];
      delete localStorage[name + "_type"];
    },

    listen: function(e) {
      $.event.trigger({
        type: "core.storage." + e.originalEvent.key,
        name: e.originalEvent.key,
        new_val: e.originalEvent.newValue,
        old_val: e.originalEvent.oldValue
      });
    }
  }
};

$(document).ready(function() {
  /*
   * Поиск и подстановка CSRF-токена во все HTTP заголовки при XHR-запросах
   */
  var csrfToken = $("meta[name=csrf-token]").attr("content");
  if (csrfToken) {
    $.ajaxSetup({
      headers: {
        "X-CSRF-Token": csrfToken
      }
    });
  }

  core.user.platform();
  core.user.browser();
  core.user.scrollSize();

  core.windowActive = document.hidden !== undefined ? document.hidden : true;

  $(window)
    .on({
      keydown: function(event) {
        core.user.key.code = event.keyCode ? event.keyCode : event.which;
      },
      keyup: function() {
        core.user.key.i++;
        core.user.key.code = 0;
      },
      resize: function() {
        core.user.winWidth = $(this).width();
        core.user.winHeight = $(this).height();
      },
      scroll: function() {
        var scrollTop = $(this).scrollTop();

        core.user.scrollDirection =
          core.user.scrollTop - scrollTop > 0 ? "up" : "down";
        core.user.scrollTop = scrollTop > 0 ? scrollTop : 0;
        core.user.scrollBottom = scrollTop + core.user.winHeight;
      },
      storage: core.storage.listen,
      offline: function() {
        if (!$("#workspace_offline").length) {
          core.draw.window({
            id: "workspace_offline",
            title: "Нет подключения к интернету",
            close: {
              overlay: false,
              esc: false,
              type: "none",
              speed: 0
            },
            text:
              'Проверьте подключение к интернету и попробуйте <a href="#" onclick="location.reload();return false;">обновить страницу</a>. Если проблема сохраняется, обратитесь к администратору используемой сети.'
          });
        }
      },
      focus: function() {
        core.windowActive = true;
      },
      blur: function() {
        core.windowActive = false;
      }
    })
    .trigger("resize");

  $(document).on({
    mouseover: function() {
      core.user.tab_active = true;
    },
    mouseleave: function() {
      core.user.tab_active = false;
      core.user.key.code = 0;
    },
    mousemove: function(e) {
      core.user.mouseX = e.pageX;
      core.user.mouseY = e.pageY;
    }
  });

  core.nav.init();
});
